import React, { useContext } from "react";
import "./UserBalance.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
const UserBalance = () => {
    const {  authUser } = useContext(AuthContext);

    return (
    <>
      <section className="user-mobile-balance-section">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div className="user-balance">
                <p>Total balance (USDT)</p>
                <h5>{authUser?.balance}</h5>
                <span>≈ ${authUser?.balance}</span>
            </div>
            <Link to="/user/assets/deposit" className="user-btn1">
            Add funds
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserBalance;
