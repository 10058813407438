import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ShortModal from "./Modal/ShortModal/ShortModal";
import LongModal from "./Modal/LongModal/LongModal";
import { Offcanvas } from "react-bootstrap";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";
import MobileTradeSideBar from "../MobileTradeSideBar/MobileTradeSideBar";
import MobileTradingViewWidget from "../../../MobileTradingViewWidget/MobileTradingViewWidget";
const PopularMarketTrade = () => {
    const { id, symbol } = useParams();
  const { authUser } = useContext(AuthContext);

  const [longmodalshow, setlongmodalshow] = useState(false);
  const [shortModalShow, setShortModalShow] = useState(false);

  const [marketData, setMarketData] = useState([]);

  useEffect(() => {
    if (id) {
      fetch(
        `https://api.royeluniverse.com/api/user/trade/log/popular/market/view/${id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setMarketData(data?.data);
        });
    }
  }, [id]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        className="mobile-trade-offcanvas-area"
      >
        <MobileTradeSideBar></MobileTradeSideBar>
      </Offcanvas>

      <section className=" mobile-trade-section user-dashboard">
        <div className="row">
          <div className="col-12">
            <div className="trade-area-posce">
              <div className="charts">
                <div
                  className="charts__head"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasLeft"
                  aria-controls="offcanvasLeft"
                >
                  <div className="d-flex gap-3">
                    <div className="nav" onClick={handleShow}>
                      <img
                        src="https://cryptolly.netlify.app/assets/media/images/icons/menu.svg"
                        alt="MENU"
                      />
                    </div>
                  </div>
                  <div className="nav">
                    <button
                      className="nav__link js-tabs-link active"
                      style={{ color: "lime" }}
                    >
                      ${authUser?.balance}
                    </button>
                  </div>
                </div>
              </div>

              <div className="trade-charts-area-posce">
                <MobileTradingViewWidget
                  symbol={symbol}
                ></MobileTradingViewWidget>
              </div>
              <div className="d-flex gap-3">
                <button
                  type="button"
                  className="btn btn-success  w-100 "
                  data-bs-toggle="modal"
                  style={{ borderRadius: "30px" }}
                  onClick={() => setlongmodalshow(true)}
                >
                  Long
                </button>

                <button
                  type="button"
                  className="btn btn-danger  w-100"
                  data-bs-toggle="modal"
                  style={{ borderRadius: "30px" }}
                  onClick={() => setShortModalShow(true)}
                >
                  Short
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <LongModal
        className="posce-trade-modal"
        marketData={marketData}
        setlongmodalshow={setlongmodalshow}
        longmodalshow={longmodalshow}
        symbol={symbol}
        id={id}
        show={longmodalshow}
        onHide={() => setlongmodalshow(false)}
      />
      <ShortModal
        className="posce-trade-modal "
        marketData={marketData}
        setShortModalShow={setShortModalShow}
        shortModalShow={shortModalShow}
        symbol={symbol}
        id={id}
        show={shortModalShow}
        onHide={() => setShortModalShow(false)}
      />
    </>
  );
};

export default PopularMarketTrade;