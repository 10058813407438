import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";
import { io } from "socket.io-client";
import axios from "axios";
import SingleMessageItem from "./SingleMessageItem";
const ENDPOINT = "https://api.royeluniverse.com"; //
let socket;

const ChatPage = ({ ChatPageHide }) => {
  const { authUser } = useContext(AuthContext);

  const [ConversationData, setConversationData] = useState([]);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [socketConnected, setSocketConnected] = useState(false);
  const [typing, setTyping] = useState(false);
  const [istyping, setIsTyping] = useState(false);

  const startMessageRef = useRef();
  const userInfoRef = useRef();

  const [UserInfo, setUserInfo] = useState(null);
  const [MessageUserInfo, setMessageUserInfo] = useState([]);

  socket = io(ENDPOINT);

  useEffect(() => {
    socket.on("recvice_message", (data) => {
      console.log(data)
      setMessages((oldMessage) => [...oldMessage, data]);
      // setMessages([...messages, data]);
    });
  });

  useEffect(() => {
    if (UserInfo) {
      fetch(
        `https://api.royeluniverse.com/api/chat/messgae/view/${UserInfo?.user_email}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setMessages(data.data);
          setConversationData(data?.ConversationData);
        });
    }
  }, [UserInfo]);

  useEffect(() => {
    // socket = io(ENDPOINT);
    // socket.emit("join_room", authUser?._id);
    // socket.on("connected", () => setSocketConnected(true));
    // socket.on("typing", () => setIsTyping(true));
    // socket.on("stop typing", () => setIsTyping(false));
  }, []);

  const typingHandler = (e) => {
    setNewMessage(e.target.value);
  };

  const [userFile, setUserFile] = useState('');

  const handleFile = (e) => {
    setUserFile(e.target.files[0])

  }


  const sendMessage = async (event) => {
    event.preventDefault();
    try {
      if (UserInfo?.user_email) {
        const storeData = { message: newMessage, attach: userFile };


        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        axios
          .post(
            `https://api.royeluniverse.com/api/chat/messgae/send/${UserInfo?.user_email}`,
            storeData,
            config
          )
          .then(async (data) => {
            event.target.reset();
            const NewMessages = data?.data?.data;
            if (NewMessages) {
              await socket.emit("send_message", NewMessages);
              setMessages([...messages, NewMessages]);
            }
          })
          .catch((error) => { });
      }
    } catch (error) { }
  };

  const [dataValue, setDataValue] = useState({});

  const StartMessage = async (event) => {
    event.preventDefault();

    try {
      if (authUser?.email) {
        const storeData = { fname: authUser?.fname, lname: authUser?.lname, email: authUser?.email };
        const config = {
          headers: {
            "content-type": "application/json",
          },
        };
        axios
          .post(
            `https://api.royeluniverse.com/api/chat/messgae/send/${authUser?.email}`,

            storeData,
            config
          )
          .then(async (data) => {
            event.target.reset();

            const results = data?.data?.ConversationData;
            if (UserInfo === null) {
              localStorage.setItem(
                "gffex_live_message",
                JSON.stringify(results)
              );
            } else {
              localStorage.removeItem("gffex_live_message");
              setMessageUserInfo(null);
              localStorage.setItem(
                "gffex_live_message",
                JSON.stringify(results)
              );
            }
            socket = io(ENDPOINT);
            socket.emit("join_room", results?.user_email);
            setMessageUserInfo(results);
            startMessageRef.current.style.display = "block";
            userInfoRef.current.style.display = "none";
          })
          .catch((error) => { });
      } else {
        const storeData = { fname: dataValue?.fname, lname: dataValue?.lname, email: dataValue?.email };
        const config = {
          headers: {
            "content-type": "application/json",
          },
        };
        axios
          .post(
            `https://api.royeluniverse.com/api/chat/messgae/send/${dataValue?.email}`,
            storeData,
            config

          )
          .then(async (data) => {
            event.target.reset();
            const results = data?.data?.ConversationData;
            if (UserInfo === null) {
              localStorage.setItem(
                "gffex_live_message",
                JSON.stringify(results)
              );

            } else {
              localStorage.removeItem("gffex_live_message");
              setMessageUserInfo(null);
              localStorage.setItem(
                "gffex_live_message",
                JSON.stringify(results)
              );

            }
            socket = io(ENDPOINT);
            socket.emit("join_room", results?.user_email);
            setMessageUserInfo(results);
            startMessageRef.current.style.display = "block";
            userInfoRef.current.style.display = "none";
          })
          .catch((error) => { });
      }
    } catch (error) { }
  };

  useEffect(() => {
    setUserInfo(JSON.parse(localStorage.getItem("gffex_live_message")));
  }, [MessageUserInfo]);

  const handleInputBlur = (event) => {
    const value = event.target.value;
    const field = event.target.name;
    const newUser = { ...dataValue };
    newUser[field] = value;
    setDataValue(newUser);
  };

  return (
    <>
      <section className="chat-section-area">
        <div className="row">
          <div className="col-md-12 shadow-lg">
            <div
              className="box box-success direct-chat direct-chat-success"
              style={{ display: "none" }}
              ref={startMessageRef}
            >
              <div className="box-header with-border">
                <h3 className="box-title">Live Chat</h3>
                <div className="box-tools pull-right">


                  <button
                    type="button"
                    className="btn btn-box-tool"
                    data-widget="remove"
                    onClick={ChatPageHide}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              </div>
              <div className="box-body">
                <div className="direct-chat-messages">
                  {messages?.length !== 0 ? (
                    messages
                      .reduce((finalArray, current) => {
                        let obj = finalArray.find(
                          (item) => item?._id === current?._id
                        );
                        if (obj) {
                          return finalArray;
                        }
                        return finalArray.concat([current]);
                      }, [])
                      .map((data, index) => {
                        if (data) {
                          return (
                            <SingleMessageItem
                              data={data}
                              key={data?._id}
                              index={index}
                              ConversationData={ConversationData}
                            ></SingleMessageItem>
                          );
                        }
                      })
                  ) : (
                    <div className="text-center mt-3">Message not found</div>
                  )}
                </div>

              </div>

              <div className="box-footer">
                <form onSubmit={sendMessage}>
                  <div className="input-group">
                    <input
                      type="text"
                      name="message"
                      placeholder="Type Message ..."
                      className="form-control"
                      onChange={typingHandler}
                    />
                    {/* <input type="file" onChange={handleFile} name='attach' className="form-control" id="exampleFormControlInput2" aria-describedby="emailHelp" /> */}
                    <span className="input-group-btn d-flex">
                      <div className="chat-file-upload mx-1">
                        <label class="filelabel">
                          <i class="fa fa-paperclip">
                          </i>
                          <input class="FileUpload1" id="FileInput"  name='attach' onChange={handleFile} type="file" accept=".jpg, .png, .jpeg, .JPG, .PNG, .JPEG" />
                        </label>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-success btn-flat chat-file-btn"
                      >
                        {/* Send */}
                        <i className="fa-regular fa-paper-plane"></i>
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
            <form onSubmit={StartMessage}>
              <div
                className="box box-success direct-chat direct-chat-success"
                ref={userInfoRef}
              >
                <div className="box-header with-border">
                  <h3 className="box-title">Live Chat</h3>
                  <div className="box-tools pull-right">

                    <button
                      type="button"
                      className="btn btn-box-tool"
                      data-widget="collapse"
                    >
                      <i className="fa-solid fa-file-image"></i>
                    </button>

                    <button
                      type="button"
                      className="btn btn-box-tool"
                      data-widget="remove"
                      onClick={ChatPageHide}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </div>
                </div>
                <div className="box-body">
                  <div className="py-3">
                    {authUser ? (
                      <>
                        <div className="container">
                          <div className="mb-1">
                            <label
                              htmlFor="exampleFormControlInput2"
                              className="form-label"
                            >
                              First Name
                            </label>
                            <input
                              type="text"
                              name="fname"
                              className="form-control"
                              id="exampleFormControlInput2"
                              aria-describedby="emailHelp"
                              defaultValue={authUser?.fname}
                              disabled
                              onBlur={handleInputBlur}
                            />
                          </div>
                          <div className="mb-1">
                            <label
                              htmlFor="exampleFormControlInput2"
                              className="form-label"
                            >
                              Last Name
                            </label>
                            <input
                              type="text"
                              name="lname"
                              className="form-control"
                              id="exampleFormControlInput2"
                              aria-describedby="emailHelp"
                              defaultValue={authUser?.lname}
                              disabled
                              onBlur={handleInputBlur}
                            />
                          </div>
                          <div className="mb-1">
                            <label
                              htmlFor="exampleFormControlInput2"
                              className="form-label"
                            >
                              Email
                            </label>
                            <input
                              type="email"
                              name="email"
                              className="form-control"
                              id="exampleFormControlInput2"
                              aria-describedby="emailHelp"
                              defaultValue={authUser?.email}
                              disabled
                              onBlur={handleInputBlur}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="container">
                          <div className="mb-1">
                            <label
                              htmlFor="exampleFormControlInput2"
                              className="form-label"
                            >
                              First Name
                            </label>
                            <input
                              type="text"
                              name="fname"
                              className="form-control"
                              id="exampleFormControlInput2"
                              aria-describedby="emailHelp"
                              onBlur={handleInputBlur}
                            />
                          </div>
                          <div className="mb-1">
                            <label
                              htmlFor="exampleFormControlInput2"
                              className="form-label"
                            >
                              Last Name
                            </label>
                            <input
                              type="text"
                              name="lname"
                              className="form-control"
                              id="exampleFormControlInput2"
                              aria-describedby="emailHelp"
                              onBlur={handleInputBlur}
                            />
                          </div>
                          <div className="mb-1">
                            <label
                              htmlFor="exampleFormControlInput2"
                              className="form-label"
                            >
                              Email
                            </label>
                            <input
                              type="email"
                              name="email"
                              className="form-control"
                              id="exampleFormControlInput2"
                              aria-describedby="emailHelp"
                              onBlur={handleInputBlur}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                </div>
                <div className="box-footer">
                  <div className="d-grid gap-2">
                    <button type="submit" className="btn btn-success btn-flat">
                      Start Chat
                      <i className="fa-regular fa-paper-plane ms-2"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChatPage;
