import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";
import DashBoardSideBar from "../../UserDashBoard/DashBoardSideBar";
import "./LoanConfirmPage.css"
const LoanConfirmPage = () => {
  const { id, amount } = useParams();
  const { authUser } = useContext(AuthContext);
  const [loanPackageData, setLoanPackageData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      fetch(`https://api.royeluniverse.com/api/user/loan/package/view/${id}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setLoanPackageData(data?.data);
          setLoading(false);
        });
    }
  }, [id]);

  const [nominee_IDImage, setNominee_IDImage] = useState("");
  const [applicant_IDImage, setApplicant_IDImage] = useState("");
  const refSubmitDis = useRef();

  const handleNominee_IDImage = (e) => {
    setNominee_IDImage(e.target.files[0]);
  };

  const handleApplicant_IDImage = (e) => {
    setApplicant_IDImage(e.target.files[0]);
  };

  const [errorMessage, setErrorMessage] = useState([]);

  const handleSubmitData = (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    setErrorMessage();
    if (nominee_IDImage === "") {
      setErrorMessage({
        nominee_IDImage: { msg: "Nominee ID field is required" },
      });
      refSubmitDis.current.removeAttribute("disabled");
    } else if (applicant_IDImage === "") {
      setErrorMessage({
        applicant_IDImage: { msg: "Applicant ID field is required" },
      });
      refSubmitDis.current.removeAttribute("disabled");
    } else {
      const formData = new FormData(event.currentTarget);
      const InpuData = Object.fromEntries(formData);
      const storeData = {
        ...InpuData,
        user_id: authUser?._id,
        nominee_IDImage: nominee_IDImage,
        applicant_IDImage: applicant_IDImage,
        loanPackageData: loanPackageData,
        amount: amount,
      };

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(`https://api.royeluniverse.com/api/user/loan/store`, storeData, config)
        .then((data) => {
          toast.success(`${data?.data?.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          event.target.reset();
          refSubmitDis.current.removeAttribute("disabled");
        })
        .catch((error) => {
          refSubmitDis.current.removeAttribute("disabled");
          if (error?.response?.data?.errors) {
            setErrorMessage(error?.response?.data?.errors);
          } else {
            setErrorMessage(error?.response?.data);
          }
        });
    }
  };

  if (loading) {
    return;
  }

  if (loanPackageData?._id) {
    return (
      <>
        <section className="user-dashboard">
          <div className="row">
            <div className="col-3 user-menu-left">
              <DashBoardSideBar></DashBoardSideBar>
            </div>
            <div className="col-9">
              <section className="loan-confirm-page-section">
                <div className="container">
                  <div className="row">
                    <div className="col-7">
                      <div className="user-deposit-section box-shadow rounded-10 p-20 box-border">
                        <div className="card h-100">
                          <div className="card-body">
                            <form onSubmit={handleSubmitData}>
                              <div className="row gutters">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                  <h6 className="mb-3 text-primary">
                                    Application Form
                                  </h6>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor="fname"
                                      className="form-label"
                                    >
                                      Full Name
                                    </label>
                                    <input
                                      type="text"
                                      name="fname"
                                      className="form-control"
                                      id="fname"
                                      aria-describedby="emailHelp"
                                    />
                                    <span className="text-danger pt-2">
                                      {errorMessage?.fname &&
                                        errorMessage?.fname?.msg}
                                    </span>
                                    <span className="text-danger pt-2">
                                      {errorMessage?.message &&
                                        errorMessage?.message}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor="email"
                                      className="form-label"
                                    >
                                      Email
                                    </label>
                                    <input
                                      type="text"
                                      name="email"
                                      className="form-control"
                                      id="email"
                                      aria-describedby="emailHelp"
                                    />
                                    <span className="text-danger pt-2">
                                      {errorMessage?.email &&
                                        errorMessage?.email?.msg}
                                    </span>
                                    <span className="text-danger pt-2">
                                      {errorMessage?.message &&
                                        errorMessage?.message}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor="gender"
                                      className="form-label"
                                    >
                                      Gender
                                    </label>
                                    <select
                                      name="gender"
                                      id="gender"
                                      className="form-select"
                                    >
                                      <option value="" selected disabled>
                                        Select One
                                      </option>
                                      <option value="Male">Male</option>
                                      <option value="Female">Female</option>
                                      <option value="3rd Gender">
                                        3rd Gender
                                      </option>
                                    </select>
                                    <span className="text-danger pt-2">
                                      {errorMessage?.gender &&
                                        errorMessage?.gender?.msg}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor="phone"
                                      className="form-label"
                                    >
                                      Phone Number
                                    </label>
                                    <input
                                      type="text"
                                      name="phone"
                                      className="form-control"
                                      id="phone"
                                      aria-describedby="emailHelp"
                                    />
                                    <span className="text-danger pt-2">
                                      {errorMessage?.phone &&
                                        errorMessage?.phone?.msg}
                                    </span>
                                    <span className="text-danger pt-2">
                                      {errorMessage?.message &&
                                        errorMessage?.message}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor="street"
                                      className="form-label"
                                    >
                                      Street Address
                                    </label>
                                    <input
                                      type="text"
                                      name="street"
                                      className="form-control"
                                      id="street"
                                      aria-describedby="emailHelp"
                                    />
                                    <span className="text-danger pt-2">
                                      {errorMessage?.street &&
                                        errorMessage?.street?.msg}
                                    </span>
                                    <span className="text-danger pt-2">
                                      {errorMessage?.message &&
                                        errorMessage?.message}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor="city"
                                      className="form-label"
                                    >
                                      City
                                    </label>
                                    <input
                                      type="text"
                                      name="city"
                                      className="form-control"
                                      id="city"
                                      aria-describedby="emailHelp"
                                    />
                                    <span className="text-danger pt-2">
                                      {errorMessage?.city &&
                                        errorMessage?.city?.msg}
                                    </span>
                                    <span className="text-danger pt-2">
                                      {errorMessage?.message &&
                                        errorMessage?.message}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor="state"
                                      className="form-label"
                                    >
                                      State
                                    </label>
                                    <input
                                      type="text"
                                      name="state"
                                      className="form-control"
                                      id="state"
                                      aria-describedby="emailHelp"
                                    />
                                    <span className="text-danger pt-2">
                                      {errorMessage?.state &&
                                        errorMessage?.state?.msg}
                                    </span>
                                    <span className="text-danger pt-2">
                                      {errorMessage?.message &&
                                        errorMessage?.message}
                                    </span>
                                  </div>
                                </div>

                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor="zip_code"
                                      className="form-label"
                                    >
                                      Zip Code
                                    </label>
                                    <input
                                      type="text"
                                      name="zip_code"
                                      className="form-control"
                                      id="zip_code"
                                      aria-describedby="emailHelp"
                                    />
                                    <span className="text-danger pt-2">
                                      {errorMessage?.zip_code &&
                                        errorMessage?.zip_code?.msg}
                                    </span>
                                    <span className="text-danger pt-2">
                                      {errorMessage?.message &&
                                        errorMessage?.message}
                                    </span>
                                  </div>
                                </div>

                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor="nominee_name"
                                      className="form-label"
                                    >
                                      Nominee Name
                                    </label>
                                    <input
                                      type="text"
                                      name="nominee_name"
                                      className="form-control"
                                      id="nominee_name"
                                      aria-describedby="emailHelp"
                                    />
                                    <span className="text-danger pt-2">
                                      {errorMessage?.nominee_name &&
                                        errorMessage?.nominee_name?.msg}
                                    </span>
                                    <span className="text-danger pt-2">
                                      {errorMessage?.message &&
                                        errorMessage?.message}
                                    </span>
                                  </div>
                                </div>

                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor="nominee_phone"
                                      className="form-label"
                                    >
                                      Nominee Phone Number
                                    </label>
                                    <input
                                      type="text"
                                      name="nominee_phone"
                                      className="form-control"
                                      id="nominee_phone"
                                      aria-describedby="emailHelp"
                                    />
                                    <span className="text-danger pt-2">
                                      {errorMessage?.nominee_phone &&
                                        errorMessage?.nominee_phone?.msg}
                                    </span>
                                    <span className="text-danger pt-2">
                                      {errorMessage?.message &&
                                        errorMessage?.message}
                                    </span>
                                  </div>
                                </div>

                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor="exampleFormControlInput2"
                                      className="form-label"
                                    >
                                      Nominee ID
                                    </label>
                                    <br />
                                    <label className="deposit-input-file">
                                      <b className="deposit-input-file-btn deposit-input-file-btn-primary">
                                        <i className="deposit-input-file-icons"></i>
                                        Choose a File
                                      </b>
                                      <input
                                        onChange={handleNominee_IDImage}
                                        type="file"
                                        className="deposit-input-file-fileInput"
                                        accept=" .jpg,  .jpeg,  .png, "
                                      />
                                    </label>
                                    <br />

                                    {errorMessage?.nominee_IDImage && (
                                      <span className="text-danger pt-2">
                                        {errorMessage?.nominee_IDImage?.msg}
                                      </span>
                                    )}

                                    <pre className="text--base mt-1">
                                      Supported mimes: jpg,jpeg,png
                                    </pre>
                                  </div>
                                </div>

                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor="exampleFormControlInput2"
                                      className="form-label"
                                    >
                                      Applicant s ID
                                    </label>
                                    <br />
                                    <label className="deposit-input-file">
                                      <b className="deposit-input-file-btn deposit-input-file-btn-primary">
                                        <i className="deposit-input-file-icons"></i>
                                        Choose a File
                                      </b>
                                      <input
                                        onChange={handleApplicant_IDImage}
                                        type="file"
                                        className="deposit-input-file-fileInput"
                                        accept=" .jpg,  .jpeg,  .png, "
                                      />
                                    </label>
                                    <br />
                                    {errorMessage?.applicant_IDImage && (
                                      <span className="text-danger pt-2">
                                        {errorMessage?.applicant_IDImage?.msg}
                                      </span>
                                    )}

                                    <pre className="text--base mt-1">
                                      Supported mimes: jpg,jpeg,png
                                    </pre>
                                  </div>
                                </div>
                              </div>
                              <div className="user-submit-btn">
                                <button
                                  type="submit"
                                  id="submit"
                                  className="btn btn-primary px-5 fw-normal d-block mt-1"
                                  ref={refSubmitDis}
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="user-loan-details-area box-shadow rounded-10 p-20 box-border">
                        <div class="card">
                          <div class="card-body">
                            <h5 class="text-center pb-2">
                              You are aplying to take loan
                            </h5>
                            <p class="text-center text-danger">
                              (Be Sure Before Confirm)
                            </p>
                            <ul>
                              <li class="user-details-card">
                                <span class="fw-bold">Plan Name: </span>
                                <span>{loanPackageData?.name}</span>
                              </li>

                              <li class="user-details-card">
                                <span class="fw-bold">Loan Amount: </span>
                                <span>${amount}</span>
                              </li>

                              <li class="user-details-card">
                                <span class="fw-bold">Total Installment: </span>
                                <span>1</span>
                              </li>

                              <li class="user-details-card">
                                <span class="fw-bold">Per Installment: </span>
                                <span>${amount}</span>
                              </li>

                              <li class="user-details-card text-danger">
                                <span class="fw-bold">You Need To Pay: </span>
                                <span class="fw-bold">${amount}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default LoanConfirmPage;
