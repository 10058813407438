import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SingleItem from './SingleItem';

const Crypto = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        fetch(`https://api.royeluniverse.com/api/frontend/home/crypto/currency/view`, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(data => {
                setData(data.data);
                setLoading(false);
            });


    }, [])

    
    const SymbolString = data.reduce(
        (acc, curr) => `${acc}${curr.Symbol}${curr.currency},`,
        ""
      );
      const Symbol = SymbolString.substring(0, SymbolString?.length - 1);
    
      const [livePrice, setLivePrice] = useState([]);
    
      useEffect(() => {
        fetchData();
      }, [data]);
    
      const fetchData = async () => {
        if (Symbol?.length !== 0) {
          const data = await fetch(
            `https://marketdata.tradermade.com/api/v1/live?currency=${Symbol}&api_key=SA2ZwXn7pBYC-odwXamW`
          );
          const apiResponse = await data.json();
          setLivePrice(apiResponse?.quotes);
        }
      };


    return (
        <>
         

         <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-top"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                tabindex="0"
              >
                
                <div className="d-flex gap-2 flex-column my-3" id="coinContainer">
                {data.map((data, index) => <SingleItem data={data} key={data?._id} index={index} livePrice={livePrice}></SingleItem>)}

                    </div>
              </div>

             
            </div>

        </>
    );
};

export default Crypto;