import React, { useContext, useEffect, useRef, useState } from "react";
import { Circles } from "react-loader-spinner";
import SingleItem from "./SingleItem";
import axios from "axios";
import { toast } from "react-toastify";
import CustomPaginationMobile from "../../../../CustomPagination/CustomPaginationMobile";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";
import MobileHistorySingleItems from "./MobileHistorySingleItems";
import Loader from "../../../../Loader/Loader";

const MobileGoldMining = () => {
  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [paginateData, setPaginateData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://api.royeluniverse.com/api/user/mining/gold/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data);
        setLoading(false);
      });
  }, []);

  const handlePage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `https://api.royeluniverse.com/api/user/mining/gold/view/${authUser?._id}?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setData(response?.data?.data);
          setPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };

  const [calData, setCalData] = useState([]);
  const [DailyProfit, setDailyProfit] = useState(0.0);
  const [TotalProfit, setTotalProfit] = useState(0.0);
  const [dataValue, setDataValue] = useState({});
  const handleSelect = (e) => {
    if (e.target.value) {
      fetch(
        `https://api.royeluniverse.com/api/user/mining/view/${e.target.value}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (dataValue?.amount) {
            if (
              parseFloat(dataValue?.amount) < parseFloat(data?.data?.min_amount)
            ) {
              setDailyProfit(`min:$${data?.data?.min_amount}`);
              setTotalProfit(`min:$${data?.data?.min_amount}`);
            } else if (
              parseFloat(dataValue?.amount) > parseFloat(data?.data?.max_amount)
            ) {
              setDailyProfit(`max:$${data?.data?.max_amount}`);
              setTotalProfit(`max:$${data?.data?.max_amount}`);
            }
            if (data?.data?.principal === 1) {
              const percentage = parseFloat(data?.data?.percentage);
              const Dailysum =
                (parseFloat(dataValue?.amount) * parseFloat(percentage)) / 100;
              const TotalProfitsum =
                parseFloat(Dailysum) * parseFloat(data?.data?.period) +
                parseFloat(dataValue?.amount);
              setDailyProfit(Dailysum);
              setTotalProfit(TotalProfitsum);
            } else {
              const percentage = parseFloat(data?.data?.percentage);
              const Dailysum =
                (parseFloat(dataValue?.amount) * parseFloat(percentage)) / 100 -
                parseFloat(dataValue?.amount);
              const TotalProfitsum =
                parseFloat(Dailysum) * parseFloat(data?.data?.period) +
                parseFloat(dataValue?.amount);
              setDailyProfit(Dailysum.toFixed(2));
              setTotalProfit(TotalProfitsum.toFixed(2));
            }

            setCalData(data?.data);
          } else {
            setCalData(data?.data);
          }
        });
    }
  };

  const handleInputBlur = (event) => {
    if (calData?._id) {
      const value = event.target.value;
      const field = event.target.name;
      if (parseFloat(value) < parseFloat(calData?.min_amount)) {
        setDailyProfit(`min:$${calData?.min_amount}`);
        setTotalProfit(`min:$${calData?.min_amount}`);
      } else if (parseFloat(value) > parseFloat(calData?.max_amount)) {
        setDailyProfit(`max:$${calData?.max_amount}`);
        setTotalProfit(`max:$${calData?.max_amount}`);
      } else if (calData?.principal === 1) {
        const percentage = parseFloat(calData?.percentage);
        const Dailysum = (parseFloat(value) * parseFloat(percentage)) / 100;
        const TotalProfitsum =
          parseFloat(Dailysum) * parseFloat(calData?.period) +
          parseFloat(value);
        setDailyProfit(Dailysum);
        setTotalProfit(TotalProfitsum);
      } else {
        const percentage = parseFloat(calData?.percentage);
        const Dailysum =
          (parseFloat(value) * parseFloat(percentage)) / 100 -
          parseFloat(value);
        const TotalProfitsum =
          parseFloat(Dailysum) * parseFloat(calData?.period) +
          parseFloat(value);
        setDailyProfit(Dailysum.toFixed(2));
        setTotalProfit(TotalProfitsum.toFixed(2));
      }
      const newUser = { ...dataValue };
      newUser[field] = value;
      setDataValue(newUser);
    } else {
      const value = event.target.value;
      const field = event.target.name;
      const newUser = { ...dataValue };
      newUser[field] = value;
      setDataValue(newUser);
    }
  };

  // Handle submit form

  const refSubmitDis = useRef();
  const [miningInputData, setMiningInputData] = useState([]);
  const [findMiningId, setFindMiningId] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);

  const handleSubmitData = async (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    try {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      await axios
        .post(
          `https://api.royeluniverse.com/api/user/mining/store/${findMiningId}`,
          { ...miningInputData, user_id: authUser?._id },
          config
        )
        .then((data) => {
          setUpdateData(data);
          toast.success(`${data.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          refSubmitDis.current.removeAttribute("disabled");
          event.target.reset();
        })
        .catch((error) => {
          setErrorMessage(error?.response?.data);
          toast.error(`${error?.response?.data?.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          refSubmitDis.current.removeAttribute("disabled");
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleMiningInputBlur = (event) => {
    const value = event.target.value;
    const field = event.target.name;
    const newUser = { ...miningInputData };
    newUser[field] = value;
    setMiningInputData(newUser);
  };

  const [GoldHistory, setGoldHistory] = useState([]);
  const [GoldHistoryPaginateData, setGoldHistoryPaginateData] = useState([]);

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `https://api.royeluniverse.com/api/user/mining/gold/history/view/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setGoldHistory(data.data);
          setGoldHistoryPaginateData(data);
        });
    }
  }, [authUser?._id, updateData]);

  const GoldHistoryhandlePage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `https://api.royeluniverse.com/api/user/mining/gold/history/view/${authUser?._id}?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setGoldHistory(response?.data?.data);
          setGoldHistoryPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  } else {
    return (
      <>
        <section >
            <div className="container-custom">
              <div className="py-4">
                <div className="user-deposit-title py-3">
                  <h1>Gold Mining</h1>
                </div>

                <div className="row pb-5">
                  <div class="mining-area">
                    <div class="row gy-5">
                      {data?.length !== 0 ? (
                        data.map((data, index) => {
                          if (data) {
                            return (
                              <SingleItem
                                data={data}
                                index={index}
                                key={data._id}
                                handleSubmitData={handleSubmitData}
                                handleMiningInputBlur={handleMiningInputBlur}
                                setFindMiningId={setFindMiningId}
                                refSubmitDis={refSubmitDis}
                              ></SingleItem>
                            );
                          }
                        })
                      ) : (
                        <>
                          <div className="text-center h-50">
                            <h6> Data not found</h6>
                          </div>
                        </>
                      )}
                    </div>
                        {/* paginateLinks */}
                        <CustomPaginationMobile
                            data={paginateData}
                            handlePage={handlePage}
                          ></CustomPaginationMobile>
                          {/* paginateLinks */}

                          <div className="row ">
                    <div className="calci_bg">
                      <div className="row align-items-center">
                        <div className="col-lg-4">
                          <ul className="calci">
                            <li>
                              <div className="cal_text">
                                <h3>
                                  {" "}
                                  Select Your Plan
                                  <select
                                    value={calData?._id}
                                    onChange={handleSelect}
                                    id="Ultra"
                                  >
                                    <option selected>Select Now</option>
                                    {data.map((data, idx) => {
                                      return (
                                        <option
                                          key={data?._id}
                                          value={data?._id}
                                        >
                                          {data.promotion_name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </h3>
                              </div>
                            </li>
                            <li>
                              <div className="cal_text">
                                <h3>
                                  {" "}
                                  Enter Your Amount
                                  <input
                                    type="number"
                                    className="inpts"
                                    name="amount"
                                    onChange={handleInputBlur}
                                    id="money"
                                  />
                                </h3>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div className="col-lg-4">
                          <ul className="calci1">
                            <li>
                              <div className="cal_text">
                                <h3>
                                  {" "}
                                  Daily Profit{" "}
                                  <span id="profitDaily">{DailyProfit}</span>
                                </h3>
                              </div>
                            </li>
                            <li>
                              <div className="cal_text">
                                <h3>
                                  {" "}
                                  Total Profit{" "}
                                  <span className="cl1" id="profitTotal">
                                    {TotalProfit}
                                  </span>
                                </h3>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div className="col-lg-4">
                          <div className="text_but text-center">
                            <button
                              type="submit"
                              className="but1 hvr-shutter-in-horizontal"
                            >
                              Calculate Now <i className="ri-play-fill"></i>{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  
                    {/* <!-- /////////// ///////////history  --> */}

                    <div class="col-xxl-12 col-md-12 pt-5">
                      <div class=" text-white card ">
                        <div class="card-header d-flex flex-wrap justify-content-between">
                          <h4 class="title text-primary">My Investemt</h4>
                        </div>
                        <div class="card-body  text-white p-2">
                          <div class="text-white table-responsive ">
                            <table
                              style={{ background: "#1A233A !important" }}
                              class="text-white table table-responsive-md "
                            >
                              <thead>
                                <tr
                                  style={{ color: "#bcd0f7 !important" }}
                                  class=" mx-auto table-header"
                                >
                                  <th>Invest&nbsp;Date</th>
                                  <th>Invest&nbsp;Amount</th>
                                  <th>Total&nbsp;Return</th>
                                  <th>C.Profit</th>
                                  <th>Remaining</th>
                                  <th>End&nbsp;At</th>
                                </tr>
                              </thead>
                              <tbody>
                                {GoldHistory?.length !== 0 ? (
                                  GoldHistory.map((data, index) => {
                                    if (data) {
                                      return (
                                        <MobileHistorySingleItems
                                          data={data}
                                          index={index}
                                          key={data?._id}
                                          paginateData={GoldHistoryPaginateData}
                                        ></MobileHistorySingleItems>
                                      );
                                    }
                                  })
                                ) : (
                                  <tr>
                                    <td
                                      className="text-muted text-center"
                                      colSpan="100%"
                                    >
                                      No records available
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          {/* paginateLinks */}
                          <CustomPaginationMobile
                            data={GoldHistoryPaginateData}
                            handlePage={GoldHistoryhandlePage}
                          ></CustomPaginationMobile>
                          {/* paginateLinks */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>
      </>
    );
  }
};

export default MobileGoldMining;
