import React from "react";
import { Link } from "react-router-dom";

const SingleItem = ({ data, index, livePrice }) => {
  return (
    <>
      <Link
        to={`/user/trade/stock/${data?.symbol}/${data?._id}`}
        style={{ borderBottom: "1px solid #dedede" }}
        className="d-flex shadow-md mx-2 p-2"
      >
        <div className="align-self-center d-flex">
          <span className="icon rounded-s me-2 gradient-orange shadow-bg shadow-bg-s">
            <img
              className="coin_style_div_div-img"
              style={{ borderRadius: "50%" }}
              src={`https://api.royeluniverse.com/${data?.image}`}
              alt=""
            />
          </span>
        </div>
        <div className="align-self-center ps-1">
          <h5 style={{ color: "black" }} className="pt-1 text-base mb-n1">
            {data?.name}
          </h5>
          <p style={{ color: "green" }} className="mb-0 font-11 opacity-50">
            ${livePrice[index]?.ask}
            <span className="copyright-year"></span>
          </p>
        </div>
        <div className="align-self-center ms-auto text-end">
          <h4 style={{ color: "gray" }} className="pt-1 mb-n1 text-xs">
            Trade
          </h4>
        </div>
      </Link>
    </>
  );
};

export default SingleItem;
