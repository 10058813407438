import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import { toast } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./Deposit.css";
const CryptoDeposit = ({ setUpdateData }) => {
  const { authUser } = useContext(AuthContext);
  const [GatewaysData, setGatewaysData] = useState([]);

  useEffect(() => {
    fetch(`https://api.royeluniverse.com/api/user/payment/gateways/manual/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setGatewaysData(data?.data);
      });
  }, []);

  const [SingleGatewaysData, setSingleGatewaysData] = useState([]);

  const SingleGatewaysHandle = (event) => {
    const id = event.target.value;
    if (id) {
      fetch(
        `https://api.royeluniverse.com/api/user/payment/gateways/manual/view/${id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setSingleGatewaysData(data.data);
        });
    }
  };

  const [dataValue, setDataValue] = useState({});

  const handleInputBlur = (event) => {
    const value = event.target.value;
    const field = event.target.name;
    const newUser = { ...dataValue };
    newUser[field] = value;
    setDataValue(newUser);
  };

  const [userImage, setUserImage] = useState("");
  const refSubmitDis = useRef();

  const handleImage = (e) => {
    setUserImage(e.target.files[0]);
  };

  const handleSubmitData = (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    const storeData = {
      ...dataValue,
      user_id: authUser?._id,
      screenshot: userImage,
      network_id: SingleGatewaysData?._id,
    };

    if (storeData.screenshot === "") {
      toast.error(`please choose your image`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      refSubmitDis.current.removeAttribute("disabled");
    } else if (!storeData?.network_id) {
      toast.error(`Network field is required`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      refSubmitDis.current.removeAttribute("disabled");
    } else {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(
          `https://api.royeluniverse.com/api/user/payment/gateways/manual/deposit/store`,
          storeData,
          config
        )
        .then((data) => {
          event.target.reset();
          toast.success(`${data.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          if (data?.success) {
            setUpdateData(data);
          }
          refSubmitDis.current.removeAttribute("disabled");
        })
        .catch((error) => {
          toast.error(`${error?.response?.data?.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          refSubmitDis.current.removeAttribute("disabled");
          console.log(error);
        });
    }
  };

  const handleCopy = () => {
    if (SingleGatewaysData) {
      navigator.clipboard.writeText(SingleGatewaysData?.wallet_address);
      toast.success(`copies wallets address!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.success(`Something is wrong`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <>
      <div className="col-6">
        <div className="user-profile-area">
          <div className="container my-4">
            <div className="row gutters">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="card h-100">
                  <div className="card-body">
                    <form onSubmit={handleSubmitData}>
                      <div className="row gutters">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <h6 className="mb-3 text-primary">Deposit</h6>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                          <div className="form-group">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Select Network
                            </label>
                            <select
                              name="GatewayId"
                              onChange={SingleGatewaysHandle}
                              className="form-select"
                              required
                            >
                              <option selected disabled>
                                {" "}
                                select
                              </option>

                              {GatewaysData.map((data) => {
                                return (
                                  <option value={data?._id} key={data?._id}>
                                    {data?.networkType}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        {SingleGatewaysData?.wallet_address ? (
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                            <div className="form-group">
                              <div className="gateways-data-area">
                                <div className="deposit-qr-code-area">
                                  <div className="deposit-qr-code">
                                    <span className="deposit-qr-code__angle deposit-qr-code__angle--top"></span>
                                    <LazyLoadImage
                                      alt="img"
                                      effect="blur"
                                      src={`https://api.royeluniverse.com/${SingleGatewaysData?.image}`}
                                    />
                                    <span className="deposit-qr-code__angle deposit-qr-code__angle--bottom"></span>
                                  </div>
                                  <div className="deposit-scan-me">
                                  <h6>Scan Me</h6>
                                  </div>
                                </div>

                                <div className="wallet-address">
                                  <span>Wallet Address</span>
                                  <p>{SingleGatewaysData?.wallet_address}</p>
                                  <p
                                    onClick={handleCopy}
                                    className="wallet-address-copy"
                                  >
                                    {" "}
                                    <i className="fa-solid fa-copy"></i>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                          <div className="form-group">
                            <label
                              htmlFor="exampleFormControlInput2"
                              className="form-label"
                            >
                              Amount
                            </label>
                            <input
                              type="number"
                              name="amount"
                              onBlur={handleInputBlur}
                              className="form-control"
                              id="exampleFormControlInput2"
                              aria-describedby="emailHelp"
                              required
                            />
                          </div>
                        </div>

                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                          <div className="form-group">
                            <label
                              htmlFor="exampleFormControlInput2"
                              className="form-label"
                            >
                              Transaction screenshot
                            </label>
                            <input
                              type="file"
                              name="amount"
                              onChange={handleImage}
                              className="form-control"
                              id="exampleFormControlInput2"
                              aria-describedby="emailHelp"
                              required
                              accept=" .jpg,  .jpeg,  .png, "
                            />
                            <pre className="text--base mt-1">
                              Supported mimes: jpg,jpeg,png
                            </pre>
                          </div>
                        </div>
                      </div>

                      <div className="row gutters">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="text-right">
                            <button
                              type="submit"
                              id="submit"
                              className="btn btn-primary px-5 fw-normal d-block mt-1"
                              ref={refSubmitDis}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CryptoDeposit;
