import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../../Contexts/AuthContext/AuthProvider';
import SingleItem from './SingleItem';
import axios from 'axios';
import { Circles } from 'react-loader-spinner';
import CustomPagination from '../../../../CustomPagination/CustomPagination';
import DashBoardSideBar from '../../UserDashBoard/DashBoardSideBar';

const TradeHistory = () => {
    const { authUser } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginateData, setPaginateData] = useState([]);

    useEffect(() => {
        if (authUser?._id) {
            fetch(`https://api.royeluniverse.com/api/user/trade/log/history/${authUser?._id}`, {
                method: 'GET',
            })
                .then(res => res.json())
                .then(data => {
                    setData(data.data);
                    setPaginateData(data);
                    setLoading(false);
                });
        }

    }, [authUser?._id]);

    const handlePage = (event) => {
        try {
            const pageNumber = event?.selected + 1;
            const config = {
                headers: {
                    "content-type": "application/json",
                },
            };
            axios
                .get(
                    `https://api.royeluniverse.com/api/user/trade/log/history/${authUser?._id}?page=${pageNumber}`,
                    config
                )
                .then((response) => {
                    setData(response?.data?.data);
                    setPaginateData(response?.data);
                })
                .catch((error) => { });
        } catch (error) {
            console.log(error);
        }
    };


    if (loading) {
        return (
            <>
                <div className="loader-section d-flex justify-content-center align-items-center">
                    <Circles
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>
            </>
        );

    } else {


        return (
            <>

                <section className='user-dashboard'>
                    <div className="row">
                        <div className="col-3 user-menu-left">
                            <DashBoardSideBar></DashBoardSideBar>
                        </div>
                        <div className="col-9">
                            <section className='container-custom'>

                                <div className="user-deposit-title pt-5">
                                    <h1>Trade History</h1>
                                </div>
                                <div className="user-referral-history-area ">
                                    <div className="user-referral-history-items">
                                        <table className='user-referral-history-table'>
                                            <thead>
                                                <tr className="table-headers">
                                                    <th>S.N.</th>
                                                    <th>Crypto Currency</th>
                                                    <th>Amount</th>
                                                    <th>High/Low</th>
                                                    <th>Result</th>
                                                    <th>Status</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data?.length !== 0 ? (
                                                        data.map((data, index) => {
                                                            if (data) {
                                                                return (
                                                                    <SingleItem
                                                                        data={data} index={index} key={data?._id} paginateData={paginateData}
                                                                    ></SingleItem>
                                                                );
                                                            }
                                                        })
                                                    ) : (
                                                        <tr>
                                                            <td className="text-muted text-center" colSpan="100%">
                                                                No records available
                                                            </td>
                                                        </tr>
                                                    )
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                    {/* paginateLinks */}
                                    <CustomPagination data={paginateData} handlePage={handlePage}>
                                    </CustomPagination>
                                    {/* paginateLinks */}
                                </div>

                            </section>
                        </div>
                    </div>

                </section>


            </>
        );
    }

};

export default TradeHistory;