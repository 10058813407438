import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const SingleItem = ({ data, index ,livePrice}) => {
    return (
        <>
             <div className="bg-white  hot-coin">
                    <Link to={`/user/trade/crypto/${data?.Symbol}${data?.currency}/${data?._id}`}>
                      <div className="wrapper">
                        <div className="d-flex align-items-center gap-2">
                          <div className="mr-2">
                          <LazyLoadImage
                                        effect="blur"
                                        alt='img'
                                        src={`https://api.royeluniverse.com/${data?.image}`} className="hot-coin-img" />

                          </div>
                          <div className="flex-grow-1">
                            <div className="text-dark font-light">
                              <span className="font-medium text-lg">{data?.Name}</span>
                              <span className="text-base">/{data?.currency}</span>
                            </div>
                            
                          </div>
                        </div>
                     
                      </div>
                    </Link>
                    <div className="exchange">
                    <div className="text-base font-light text-up">
                            {livePrice[index]?.ask}$
                            </div>
                    </div>
                  </div>
        </>
    );
};

export default SingleItem;