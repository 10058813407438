import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import "./ContactUs.css";
const ContactUs = () => {
   

    
    const [dataValue, setDataValue] = useState({});
    const refSubmitDis = useRef();

    const handleSubmitData = async (event) => {
        event.preventDefault();
        refSubmitDis.current.setAttribute("disabled", true);
        try {

            const config = {
                headers: {
                    'content-type': 'application/json',
                }
            };

            await axios
                .post(`https://api.royeluniverse.com/api/user/subscribe/contact/us/store`, { ...dataValue }, config)
                .then(data => {
                    event.target.reset();
                    toast.success(`${data.data.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    refSubmitDis.current.removeAttribute("disabled");

                })
                .catch(error => {
                    toast.error(`${error?.response?.data?.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    refSubmitDis.current.removeAttribute("disabled");
                })



        } catch (error) {
            console.log(error);
        }



    }



    const handleInputBlur = event => {
        const value = event.target.value;
        const field = event.target.name;
        const newUser = { ...dataValue, };
        newUser[field] = value;
        setDataValue(newUser);
    }


 

    return (
        <>
            
    <section className="contact-us-area">
        <form className="contact-us" onSubmit={handleSubmitData}>
            <div className="container">
              <div className='contact-us-card'>
              <ul>
              <h1>Contact Us</h1>
                <li>
                  <div className="grid grid-1">
                    <input type="text" placeholder="Full Name" name='name' onBlur={handleInputBlur} required style={{width:"100%"}} />  
                  </div>
                </li>
                <li>
                  <div className="grid grid-2">
                    <input type="email"  name='email' onBlur={handleInputBlur}   placeholder="Email" required />  
                    <input type="tel" placeholder="Phone" name='phone' onBlur={handleInputBlur} required />
                  </div>
                </li>    
                <li>
                  <textarea placeholder="Message" name='message' onBlur={handleInputBlur} required></textarea>
                </li>   
            
                <li>
                  <div className="grid text-center ">
                    <button className="btn-grid" id="contact_us_btn"  ref={refSubmitDis} type='submit'>
                      <span className="front">SUBMIT</span>
                    </button>
                  </div>
                </li>    
              </ul>
              </div>
              
            </div>
          </form>
        
    </section>
        </>
    );
};

export default ContactUs;