import React, { useContext } from "react";
import "./MobileAssets.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
const MobileAssets = () => {
  const { authUser } = useContext(AuthContext);

  return (
    <>
      <section className="assets-area">
        <div className="assets-user-balance-area">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4>
                  Total Balance <i className="fa-solid fa-eye"></i>
                </h4>
                <div className="assets-user-balance">
                  <h5>{authUser?.balance}</h5>
                  <span>
                    USDT <i class="fa-solid fa-caret-down"></i>{" "}
                  </span>
                </div>
                <div className="assets-user-sub-balance">
                  <p>≈ ${authUser?.balance}</p>
                </div>
              </div>
              <Link to="" className="user-btn1">
               Exchange
              </Link>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="assets-link-area">
            <Link to="/user/assets/deposit">
              <i className="fa-solid fa-arrow-right-to-bracket fa-rotate-90"></i>
              <p>Deposit</p>
            </Link>
            <Link to="/user/assets/withdraw">
              <i className="fa-solid fa-arrow-up-from-bracket"></i>
              <p>Withdraw</p>
            </Link>
            <Link>
              <i class="fa-solid fa-shop-lock"></i>
              <p>Buy Coin</p>
            </Link>
            {/* <Link>
              <i class="fa-solid fa-arrow-right-arrow-left"></i>
              <p>Transfer</p>
            </Link> */}
          </div>

          <div className="assets-bottom-balance-area">
            <div className="assets-bottom-balances-item">
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <h5>Bitcoin</h5>
                </div>
                <div className="assets-bottom-balance">
                  <h6>{authUser?.balance} USDT</h6>
                  <p>$ {authUser?.balance}</p>
                </div>
              </div>
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <p>Daily PNL%</p>
                </div>
                <div className="assets-bottom-balance">
                  <span>+0 USDT (+0.00%)</span>
                </div>
              </div>
            </div>
            <div className="assets-bottom-balances-item">
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <h5>Ethereum</h5>
                </div>
                <div className="assets-bottom-balance">
                  <h6>{authUser?.balance} USDT</h6>
                  <p>$ {authUser?.balance}</p>
                </div>
              </div>
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <p>Daily PNL%</p>
                </div>
                <div className="assets-bottom-balance">
                  <span>+0 USDT (+0.00%)</span>
                </div>
              </div>
            </div>
            <div className="assets-bottom-balances-item">
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <h5>Solana</h5>
                </div>
                <div className="assets-bottom-balance">
                  <h6>{authUser?.balance} USDT</h6>
                  <p>$ {authUser?.balance}</p>
                </div>
              </div>
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <p>Daily PNL%</p>
                </div>
                <div className="assets-bottom-balance">
                  <span>+0 USDT (+0.00%)</span>
                </div>
              </div>
            </div>
            <div className="assets-bottom-balances-item">
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <h5>Gold</h5>
                </div>
                <div className="assets-bottom-balance">
                  <h6>{authUser?.balance} USDT</h6>
                  <p>$ {authUser?.balance}</p>
                </div>
              </div>
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <p>Daily PNL%</p>
                </div>
                <div className="assets-bottom-balance">
                  <span>+0 USDT (+0.00%)</span>
                </div>
              </div>
            </div>
            <div className="assets-bottom-balances-item">
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <h5>Tether</h5>
                </div>
                <div className="assets-bottom-balance">
                  <h6>{authUser?.balance} USDT</h6>
                  <p>$ {authUser?.balance}</p>
                </div>
              </div>
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <p>Daily PNL%</p>
                </div>
                <div className="assets-bottom-balance">
                  <span>+0 USDT (+0.00%)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MobileAssets;
