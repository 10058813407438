import React, { useEffect }  from 'react';
import './psec-style.css';
import './psec-responsive.css';
import './lightTheme.css';
import './index.css';
import './responsive.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css'
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-image-crop/dist/ReactCrop.css'
import 'antd/dist/reset.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Aos from "aos";
import "aos/dist/aos.css"
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import HomeMain from './Components/Layouts/HomeMain';
import Home from './Components/Home/Home';
import AboutsUs from './Components/Page/AboutsUs/AboutsUs';
import Portfolio from './Components/Page/Portfolio/Portfolio';
import Blogs from './Components/Page/Blogs/Blogs';
import BlogDetails from './Components/Page/Blogs/BlogDetails';
import LoginRoute from './Route/LoginRoute';
import LoginForm from './Components/Auth/LoginForm/LoginForm';
import RegisterForm from './Components/Auth/RegisterForm/RegisterForm';
import ReSetPassword from './Components/Auth/ReSetPassword/ReSetPassword';
import ForgetPassword from './Components/Auth/ForgetPassword/ForgetPassword';
import UserPrivateRoute from './Route/UserPrivateRoute';
import UserDashBoard from './Components/Page/User/UserDashBoard/UserDashBoard';
import Identification from './Components/Page/User/Identification/Identification';
import Referral from './Components/Page/User/Referral/Referral';
import Deposit from './Components/Page/User/Deposit/Deposit';
import KycPrivateRoute from './Route/KycPrivateRoute';
import Trade from './Components/Page/User/Trade/Trade';
import Withdraw from './Components/Page/User/Withdraw/Withdraw';
import TradeHistory from './Components/Page/User/Trade/TradeHistory/TradeHistory';
import TransactionHistory from './Components/Page/User/TransactionHistory/TransactionHistory';
import ThradeNow from './Components/Page/User/Trade/ThradeNow/ThradeNow';
import EnergisesTrade from './Components/Page/User/Trade/EnergisesPage/EnergisesTrade';
import StocksTrade from './Components/Page/User/Trade/StocksPage/StocksTrade';
import MetalsTrade from './Components/Page/User/Trade/MetalsPage/MetalsTrade';
import ForexTrade from './Components/Page/User/Trade/ForexPage/ForexTrade';
import IndicesTrade from './Components/Page/User/Trade/IndicesPage/IndicesTrade';
import BitCoinMining from './Components/Page/User/BitCoinMining/BitCoinMining';
import KycRoute from './Route/KycRoute';
import KycPage from './Components/Page/User/KycPage/KycPage';
import Loan from './Components/Page/User/Loan/Loan';
import ProfileUpdate from './Components/Page/User/ProfileUpdate/ProfileUpdate';
import GoldCoinMining from './Components/Page/User/GoldCoinMining/GoldCoinMining';
import PasswordChange from './Components/Page/User/PasswordChange/PasswordChange';
import MobileUserDashboard from './Components/Mobile/Home/MobileUserDashboard';
import MobileHomeMain from './Components/Mobile/Layouts/MobileHomeMain';
import MobileLoginRoute from './Route/MobileLoginRoute';
import MobileLoginForm from './Components/Auth/MobileAuth/MobileLoginForm';
import MobileRegisterForm from './Components/Auth/MobileAuth/MobileRegisterForm';
import UserMobilePrivateRoute from './Route/UserMobilePrivateRoute';
import MobileTradePage from './Components/Mobile/Pages/MobileTrade/MobileTradePage';
import MobileBitcoinMining from './Components/Mobile/Pages/MobileMining/MobileBitcoinMining/MobileBitcoinMining';
import MobileGoldMining from './Components/Mobile/Pages/MobileMining/MobileGoldMining/MobileGoldMining';
import MobileLoan from './Components/Mobile/Pages/MobileLoan/MobileLoan';

import MobileHomeRoute from './Route/MobileHomeRoute';
import MobileKycPrivateRoute from './Route/MobileKycPrivateRoute';
import News from './Components/Mobile/Pages/News/News';
import NewsDetails from './Components/Mobile/Pages/News/NewsDetails';
import MobileDepositHistory from './Components/Mobile/Pages/MobileDeposit/MobileDepositHistory/MobileDepositHistory';
import MobileWithdrawPage from './Components/Mobile/Pages/MobileWithdraw/MobileWithdrawPage';
import MobileWithdrawHistory from './Components/Mobile/Pages/MobileWithdraw/MobileWithdrawHistory/MobileWithdrawHistory';
import MobileTransaction from './Components/Mobile/Pages/MobileTransaction/MobileTransaction';
import MobileKycPage from './Components/Mobile/Pages/MobileKycPage/MobileKycPage';
import UserProfileUpdate from './Components/Mobile/Pages/User/UserProfileUpdate';
import UserPasswordChange from './Components/Mobile/Pages/User/UserPasswordChange';
import MobileAssets from './Components/Mobile/Pages/MobileAssets/MobileAssets';
import Markets from './Components/Mobile/Pages/Markets/Markets';
import PlatformApps from './Components/Page/PlatformApps/PlatformApps';
import Disclaimer from './Components/Page/Disclaimer/Disclaimer';
import Blockchain from './Components/Page/Blockchain/Blockchain';
import Announcement from './Components/Page/Announcement/Announcement';
import PrivacyPolicy from './Components/Page/PrivacyPolicy/PrivacyPolicy';
import ContactUs from './Components/Page/ContactUs/ContactUs';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import { ToastContainer } from 'react-toastify';
import MobileTrade from './Components/Mobile/Pages/MobileTrade/MobileTrade';
import MobileEnergisesTrade from './Components/Mobile/Pages/MobileTrade/MobileEnergisesTrade/MobileEnergisesTrade';
import MobileForexTrade from './Components/Mobile/Pages/MobileTrade/MobileForexTrade/MobileForexTrade';
import MobileIndicesTrade from './Components/Mobile/Pages/MobileTrade/MobileIndicesTrade/MobileIndicesTrade';
import MobileMetalsTrade from './Components/Mobile/Pages/MobileTrade/MobileMetalsTrade/MobileMetalsTrade';
import MobileStocksTrade from './Components/Mobile/Pages/MobileTrade/MobileStocksTrade/MobileStocksTrade';
import MobileContact from './Components/Mobile/Pages/MobileContact/MobileContact';
import MobileForex from './Components/Mobile/Pages/MobileForex/MobileForex';
import MobileStocks from './Components/Mobile/Pages/MobileStocks/MobileStocks';
import MobileTradeHistory from './Components/Mobile/Pages/MobileTrade/MobileTradeHistory/MobileTradeHistory';
import MobileCryptoDeposit from './Components/Mobile/Pages/MobileDeposit/MobileCryptoDeposit/MobileCryptoDeposit';
import PopularMarketTrade from './Components/Mobile/Pages/MobileTrade/PopularMarketTrade/PopularMarketTrade';
import InvestPage from './Components/Page/InvestPage/InvestPage';
import MobileInvestPlan from './Components/Mobile/Pages/MobileInvestPlan/MobileInvestPlan';
import InvestHistory from './Components/Page/InvestPage/InvestHistory/InvestHistory';
import MobileInvestmentPage from './Components/Mobile/Pages/MobileInvestmentPage/MobileInvestmentPage';
import MobileInvestPlanHistory from './Components/Mobile/Pages/MobileInvestPlan/MobileInvestPlanHistory/MobileInvestPlanHistory';
import MobileLoanConfirmPage from './Components/Mobile/Pages/MobileLoan/MobileLoanConfirmPage/MobileLoanConfirmPage';
import LoanConfirmPage from './Components/Page/User/Loan/LoanConfirmPage/LoanConfirmPage';


function App() {
  useEffect(()=>{
    Aos.init({duration:1000});
    },[])

  return (

    <>
         <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />

    <BrowserRouter>
      <ScrollToTop />
      <Routes>
          <Route path="/" element={<HomeMain />}  >
            <Route path="/" element={ <MobileHomeRoute> <Home /> </MobileHomeRoute> } />
            <Route path="dashboard" element={<UserPrivateRoute><UserDashBoard /></UserPrivateRoute>} />
            <Route path="/user/id-auth" element={<UserPrivateRoute><Identification /></UserPrivateRoute>} />
            <Route path="/user/referral" element={<UserPrivateRoute><Referral /></UserPrivateRoute>} />
            <Route path="/assets/deposit" element={<UserPrivateRoute><Deposit /></UserPrivateRoute>} />
          
            <Route path="/trade" element={<UserPrivateRoute><Trade /></UserPrivateRoute>} />
            <Route path="/trade/history" element={<UserPrivateRoute><TradeHistory /></UserPrivateRoute>} />
          
            <Route path="/assets/record" element={<UserPrivateRoute><TransactionHistory /></UserPrivateRoute>} />

            <Route path="/trade/with/:symbol/:id" element={<UserPrivateRoute><ThradeNow /></UserPrivateRoute>} />
            <Route path="/energises/trade/with/:symbol/:id" element={<UserPrivateRoute><EnergisesTrade /></UserPrivateRoute>} />
            <Route path="/stock/trade/with/:symbol/:id" element={<UserPrivateRoute><StocksTrade /></UserPrivateRoute>} />
            <Route path="/metals/trade/with/:symbol/:id" element={<UserPrivateRoute><MetalsTrade /></UserPrivateRoute>} />
            <Route path="/forex/trade/with/:symbol/:id" element={<UserPrivateRoute><ForexTrade /></UserPrivateRoute>} />
            <Route path="/indices/trade/with/:symbol/:id" element={<UserPrivateRoute><IndicesTrade /></UserPrivateRoute>} />

            <Route path="/bitcoin/mining" element={<UserPrivateRoute><BitCoinMining /></UserPrivateRoute>} />
            <Route path="/gold/coin/mining" element={<UserPrivateRoute><GoldCoinMining /></UserPrivateRoute>} />
            <Route path="/apply/loan" element={<UserPrivateRoute><Loan /></UserPrivateRoute>} />
            <Route path="/loan/confirm/:amount/:id" element={<UserPrivateRoute><LoanConfirmPage /></UserPrivateRoute>} />
            <Route path="/profile/update" element={<UserPrivateRoute><ProfileUpdate /></UserPrivateRoute>} />

            <Route path="/id-auth/Kyc" element={<UserPrivateRoute> <KycRoute><KycPage /></KycRoute></UserPrivateRoute>} />
            <Route path="/assets/withdraw" element={<UserPrivateRoute><KycPrivateRoute> <Withdraw /> </KycPrivateRoute></UserPrivateRoute>} />

            <Route path="/password/update" element={<UserPrivateRoute><PasswordChange /></UserPrivateRoute>} />

            <Route path="/invest/plan/history" element={ <InvestHistory />} />

 




{/* new  */}





 

<Route path="/portfolio" element={ <MobileHomeRoute>  <Portfolio /> </MobileHomeRoute>} />
<Route path="/news" element={ <MobileHomeRoute>  <Blogs /> </MobileHomeRoute>} />
<Route path="/news/details/:id" element={ <MobileHomeRoute>  <BlogDetails /> </MobileHomeRoute>} />
<Route path="/about/us" element={ <MobileHomeRoute>  <AboutsUs />  </MobileHomeRoute> } />
<Route path="/platform/apps" element={ <MobileHomeRoute>  <PlatformApps />  </MobileHomeRoute> } />
<Route path="/disclaimer" element={ <MobileHomeRoute>  <Disclaimer />  </MobileHomeRoute> } />
<Route path="/blockchain" element={ <MobileHomeRoute>  <Blockchain />  </MobileHomeRoute> } />
<Route path="/announcement" element={ <MobileHomeRoute>  <Announcement />  </MobileHomeRoute> } />
<Route path="/privacy/policy" element={ <MobileHomeRoute>  <PrivacyPolicy />  </MobileHomeRoute> } />
<Route path="/contact/us" element={ <MobileHomeRoute>  <ContactUs />  </MobileHomeRoute> } />
<Route path="/invest/plan" element={ <MobileHomeRoute>  <InvestPage />  </MobileHomeRoute> } />
<Route path="/investment/plan" element={ <MobileHomeRoute>  <MobileInvestmentPage />  </MobileHomeRoute> } />


          </Route>

          <Route path="login" element={<LoginRoute><LoginForm /></LoginRoute>} />
          <Route path="register" element={<LoginRoute><RegisterForm /></LoginRoute>} />
          <Route path="/forgot/password" element={<LoginRoute> <ForgetPassword /> </LoginRoute>} />
          <Route path="/reset/password/:id/:token" element={<LoginRoute> <ReSetPassword /> </LoginRoute>} />

          

{/* Mobile  */}

<Route path="/user/" element={<MobileHomeMain />}  >
<Route path="dashboard" element={<UserMobilePrivateRoute> <MobileUserDashboard /> </UserMobilePrivateRoute>} />

<Route path="trade" element={<UserMobilePrivateRoute> <MobileTradePage /> </UserMobilePrivateRoute>} />
 

<Route path="bitcoin/mining" element={<UserMobilePrivateRoute><MobileBitcoinMining /></UserMobilePrivateRoute>} />
<Route path="gold/mining" element={<UserMobilePrivateRoute><MobileGoldMining /></UserMobilePrivateRoute>} />
<Route path="apply/loan" element={<UserMobilePrivateRoute><MobileLoan /></UserMobilePrivateRoute>} />
<Route path="loan/confirm/:amount/:id" element={<UserMobilePrivateRoute><MobileLoanConfirmPage /></UserMobilePrivateRoute>} />
<Route path="news" element={<UserMobilePrivateRoute><News /></UserMobilePrivateRoute>} />
<Route path="news/details/:id" element={<UserMobilePrivateRoute><NewsDetails /></UserMobilePrivateRoute>} />


<Route path="assets/deposit" element={<UserMobilePrivateRoute><MobileCryptoDeposit /></UserMobilePrivateRoute>} />
<Route path="deposit/history" element={<UserMobilePrivateRoute><MobileDepositHistory /></UserMobilePrivateRoute>} />
<Route path="assets/withdraw" element={<UserMobilePrivateRoute><MobileKycPrivateRoute>  <MobileWithdrawPage /> </MobileKycPrivateRoute> </UserMobilePrivateRoute>} />
<Route path="withdraw/history" element={<UserMobilePrivateRoute> <MobileWithdrawHistory /></UserMobilePrivateRoute>} />

<Route path="profile/update" element={<UserMobilePrivateRoute><UserProfileUpdate /></UserMobilePrivateRoute>} />
<Route path="password/update" element={<UserMobilePrivateRoute><UserPasswordChange /></UserMobilePrivateRoute>} />

<Route path="id-auth/Kyc" element={<UserPrivateRoute> <MobileKycPage /></UserPrivateRoute>} />
<Route path="transaction/histroy" element={<UserPrivateRoute> <MobileTransaction /></UserPrivateRoute>} />
 
<Route path="assets" element={<UserPrivateRoute> <MobileAssets /></UserPrivateRoute>} />
<Route path="markets" element={<UserPrivateRoute> <Markets /></UserPrivateRoute>} />

<Route path="invest/plan" element={<UserPrivateRoute>  <MobileInvestPlan /> </UserPrivateRoute>} />
<Route path="invest/history" element={<UserPrivateRoute>  <MobileInvestPlanHistory /> </UserPrivateRoute>} />

{/* trade  */} 
<Route path="trade/crypto/:symbol/:id" element={<UserMobilePrivateRoute> <MobileTrade /> </UserMobilePrivateRoute>} />
<Route path="trade/energises/:symbol/:id" element={<UserMobilePrivateRoute> <MobileEnergisesTrade /> </UserMobilePrivateRoute>} />
<Route path="trade/forex/:symbol/:id" element={<UserMobilePrivateRoute> <MobileForexTrade /> </UserMobilePrivateRoute>} />
<Route path="trade/indices/:symbol/:id" element={<UserMobilePrivateRoute> <MobileIndicesTrade /> </UserMobilePrivateRoute>} />
<Route path="trade/metals/:symbol/:id" element={<UserMobilePrivateRoute> <MobileMetalsTrade /> </UserMobilePrivateRoute>} />
<Route path="trade/stock/:symbol/:id" element={<UserMobilePrivateRoute> <MobileStocksTrade /> </UserMobilePrivateRoute>} />
<Route path="trade/popular/market/:symbol/:id" element={<UserMobilePrivateRoute> <PopularMarketTrade /> </UserMobilePrivateRoute>} />

<Route path="trade/history" element={<UserPrivateRoute><MobileTradeHistory /></UserPrivateRoute>} />

<Route path="contact" element={<UserMobilePrivateRoute> <MobileContact /> </UserMobilePrivateRoute>} />
<Route path="forex" element={<UserMobilePrivateRoute> <MobileForex /> </UserMobilePrivateRoute>} />
<Route path="stocks" element={<UserMobilePrivateRoute> <MobileStocks /> </UserMobilePrivateRoute>} />


</Route>

<Route path="user/login" element={<MobileLoginRoute><MobileLoginForm /></MobileLoginRoute>} />
<Route path="user/register" element={<MobileLoginRoute><MobileRegisterForm /></MobileLoginRoute>} />



          {/* <Route path="*" element={<Error404Page />} /> */}
        </Routes>

    </BrowserRouter>
    
    </>
   


  );
}

export default App;