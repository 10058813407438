import React from "react";
import { Link } from "react-router-dom";

const SingleItem = ({ data, index, livePrice }) => {

  return (
    <>
      <div className="row popular-market-list">
        <div className="col-6">
          <h6>
            {data?.name}
            <span>/{data?.currency}</span>
          </h6>
        </div>
        <div className="col-6 ">
          <div className="row popular-market-list-right">
            <div className="col-6 ">
              <h6> {livePrice[index]?.bid}$</h6>
              <p> {livePrice[index]?.bid}$</p>
            </div>
            <div className="col-6 d-flex justify-content-end align-items-center">
              <Link
                className="btn-2"
                to={`/user/trade/popular/market/${data?.symbol}${data?.currency}/${data?._id}`}
              >
                0.00%
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleItem;
