import React, { useEffect, useState } from 'react';
import "./Announcement.css";
import NoticeModal from "./NoticeModal";
import SingleItem from "./SingleItem";
import axios from "axios";
import { Circles } from 'react-loader-spinner';
const Announcement = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios
            .get(`https://api.royeluniverse.com/api/user/notice/view`)
            .then((res) => {
                setData(res?.data?.data);
                setLoading(false)
            })
            .catch((err) => {
                console.log("API call error:", err?.message);
            });
    }, []);

    const [singleDataId, setSingleDataId] = useState(null);

    const [show, setShow] = useState(false);

    const NoticeModalShow = (id) => {
        setSingleDataId(id);
        setShow(true);
    };

    

	if (loading) {
		return (
			<>
				<div className="loader-section d-flex justify-content-center align-items-center">
					<Circles
						height="80"
						width="80"
						color="#4fa94d"
						ariaLabel="circles-loading"
						wrapperStyle={{}}
						wrapperClass=""
						visible={true}
					/>
				</div>
			</>
		);
	} else {
        return (
            <>
                <section className="announcement-page-area">
                    <div className="announcement-page">
                        <h1>Announcement</h1>
                            {data?.length !== 0 ? (
                                data.map((data, index) => {
                                    if (data) {
                                        return (
    
                                            <SingleItem
                                                data={data} key={data?._id} NoticeModalShow={NoticeModalShow}
                                            ></SingleItem>
    
                                            //   <div key={data?._id}>
                                            //     <p onClick={() => NoticeModalShow(data?._id)}>
                                            //       {data?.name}
                                            //     </p>
                                            //   </div>
                                        );
                                    }
                                })
                            ) : (
                                <div className="announcement-page-item">
                                     <label for="announcement-page1" className="announcement-page-item-title" >
                                        No data available
                                    </label>
                                </div>
                            )}
                        </div>
    
    
                </section>
    
                {(() => {
                    if (show) {
                        return (
                            <NoticeModal
                                singleDataId={singleDataId}
                                setShow={setShow}
                                show={show}
                            ></NoticeModal>
                        );
                    }
                })()}
    
            </>
        );


    }

   
};

export default Announcement;