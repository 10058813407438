import React from "react";
import { ToastContainer } from "react-toastify";
import MobileUserHeader from "./MobileUserHeader";
import { Outlet } from "react-router-dom";
import MobileUserFooter from "./MobileUserFooter";
import "./MobileHomeMain.css";

const MobileHomeMain = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <section className="mobile-home-main">
      <MobileUserHeader></MobileUserHeader>
      <Outlet></Outlet>
      <MobileUserFooter></MobileUserFooter>
      </section>

     
    </>
  );
};

export default MobileHomeMain;
