import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import axios from "axios";
import CustomPagination from "../../../CustomPagination/CustomPagination";
import SingleItem from "./SingleItem";
import DashBoardSideBar from "../../User/UserDashBoard/DashBoardSideBar";
import Loader from "../../../Loader/Loader";

const InvestHistory = () => {
  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [paginateData, setPaginateData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://api.royeluniverse.com/api/user/tnvestment/plan/history/view/${authUser?._id}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data);
        setPaginateData(data);
        setLoading(false);
      });
  }, []);

  const handlePage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `https://api.royeluniverse.com/api/user/tnvestment/plan/view/${authUser?._id}?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setData(response?.data?.data);
          setPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };
  if (loading) {
    return (
      <>
        <>
          <Loader />
        </>
      </>
    );
  } else {
    return (
      <>
        <section className="user-dashboard">
          <div className="row">
            <div className="col-3 user-menu-left">
              <DashBoardSideBar></DashBoardSideBar>
            </div>
            <div className="col-9">
              <section className="user-deposit pt-2">
                <div className="container-custom">
                    <div className="row pb-5">
                      <div class="mining-area">

                        <div class="col-xxl-12 col-md-12 pt-1">
                          <div class=" text-white card ">
                            <div class="card-header d-flex flex-wrap justify-content-between">
                              <h4 class="title text-primary">My Investemt Plan</h4>
                            </div>
                            <div class="card-body  text-white p-2">
                              <div class="text-white table-responsive ">
                                <table
                                  style={{ background: "#1A233A !important" }}
                                  class="text-white table table-responsive-md "
                                >
                                  <thead>
                                    <tr
                                      style={{ color: "#bcd0f7 !important" }}
                                      class=" mx-auto table-header"
                                    >
                                      <th>Invest Date</th>
                                      <th>Invest Amount</th>
                                      <th>Total Return</th>
                                      <th>C.Profit</th>
                                      <th>Remaining</th>
                                      <th>End At</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data?.length !== 0 ? (
                                      data.map((data, index) => {
                                        if (data) {
                                          return (
                                            <SingleItem
                                              data={data}
                                              index={index}
                                              key={data?._id}
                                              paginateData={paginateData}
                                            ></SingleItem>
                                          );
                                        }
                                      })
                                    ) : (
                                      <tr>
                                        <td
                                          className="text-muted text-center"
                                          colSpan="100%"
                                        >
                                          No records available
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              {/* paginateLinks */}
                              <CustomPagination
                                data={paginateData}
                                handlePage={handlePage}
                              ></CustomPagination>
                              {/* paginateLinks */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default InvestHistory;
