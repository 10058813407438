import React, { useEffect, useState } from 'react';
import SingleItem from './SingleItem';

const Stock = ({handleClose}) => {
    const [data, setData] = useState([]);


    useEffect(() => {
        fetch(`https://api.royeluniverse.com/api/user/trade/market/stocks/view`, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(data => {
                setData(data.data);
            });


    }, [])

   

    const StocksSymbolString = data.reduce((acc, curr) => `${acc}${curr.symbol},`, "");
    const StocksSymbol = StocksSymbolString.substring(0, StocksSymbolString?.length - 1);
  
    const [livePrice, setLivePrice] = useState([]);
  
    useEffect(() => {
        StocksfetchData();
    }, [data]);
  
    const StocksfetchData = async () => {
      if (StocksSymbol?.length !== 0) {
        const data = await fetch(
          `https://marketdata.tradermade.com/api/v1/live?currency=${StocksSymbol}&api_key=E973yln-XwbcM9nvff0R`
        );
        const apiResponse = await data.json();
        setLivePrice(apiResponse?.quotes);
      }
    };
  
    return (
        <>
             <div className="tab-content" id="pills-tabContent">
        <div className="tab-pane fade show active">
          <ul className="coin_menu mt-2">
          {data.map((data, index) => <SingleItem data={data} key={data?._id} index={index} livePrice={livePrice} handleClose={handleClose}></SingleItem>)}

            {/* <li>
              <a
                className="menu-item active"
                href="https://swoopventures.com/user/trades?coin=DOGE"
              >
                <div className="d-flex justify-content-between gap-1">
                  <div className="text-xl">
                    <span>DOGE</span>
                    <span className="opacity-50 text-base font-light">
                      /USDT
                    </span>
                  </div>

                  <div className="d-flex justify-content-between mt-1">
                    <div className="text-gray-400 font-light text-sm  trade-sidebar-price">
                      0.1
                    </div>
                  </div>
                </div>
              </a>
            </li> */}
          </ul>
        </div>
      </div>
        </>
    );
};

export default Stock;