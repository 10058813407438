import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import SingleItem from "./SingleItem";
import CustomPaginationMobile from "../../../CustomPagination/CustomPaginationMobile";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import MobileLoanSingleHistoryItem from "./MobileLoanSingleHistoryItem";
import Loader from "../../../Loader/Loader";
import {useNavigate } from "react-router-dom";

const MobileLoan = () => {
  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [paginateData, setPaginateData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://api.royeluniverse.com/api/user/loan/package/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data);
        setLoading(false);
      });
  }, []);

  const handlePage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `https://api.royeluniverse.com/api/user/loan/package/view/${authUser?._id}?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setData(response?.data?.data);
          setPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };

  const [loanHistory, setLoanHistory] = useState([]);
  const [loanHistoryPaginateData, setLoanHistoryPaginateData] = useState([]);

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `https://api.royeluniverse.com/api/user/loan/history/view/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setLoanHistory(data.data);
          setLoanHistoryPaginateData(data);
          setLoading(false);
        });
    }
  }, [authUser?._id]);

  const handleloanHistoryPage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `https://api.royeluniverse.com/api/user/loan/history/view/${authUser?._id}?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setLoanHistory(response?.data?.data);
          setLoanHistoryPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };

  // Handle submit form

  const refSubmitDis = useRef();
  const [errorMessage, setErrorMessage] = useState([]);
  const navigate = useNavigate();

  const handleSubmitData = async (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);
    try {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      await axios
        .post(
          `https://api.royeluniverse.com/api/user/loan/amount/check`,
          { ...InpuData, user_id: authUser?._id },
          config
        )
        .then((data) => {
          const packageId = data?.data?.data?._id;
          const Location =  `/user/loan/confirm/${data?.data?.amount}/${packageId}`;
          navigate(Location)
          refSubmitDis.current.removeAttribute("disabled");
            event.target.reset();
        })
        .catch((error) => {
          setErrorMessage(error?.response?.data);
          refSubmitDis.current.removeAttribute("disabled");
        });
    } catch (error) {
      console.log(error);
    }
  };



  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  } else {
    return (
      <>
        <section class="user-loan-area">
          <div class="container col-12 text-right py-2">
            <div class="row g-4 justify-content-center">
              {data?.length !== 0 ? (
                data.map((data, index) => {
                  if (data) {
                    return (
                      <SingleItem
                        data={data}
                        index={index}
                        key={data._id}
                        handleSubmitData={handleSubmitData}
                        refSubmitDis={refSubmitDis}
                        errorMessage={errorMessage}
                      ></SingleItem>
                    );
                  }
                })
              ) : (
                <>
                  <div className="text-center h-50">
                    <h6> Data not found</h6>
                  </div>
                </>
              )}
            </div>
            <div className="pt-3">
              {/* paginateLinks */}
              <CustomPaginationMobile
                data={paginateData}
                handlePage={handlePage}
              ></CustomPaginationMobile>
              {/* paginateLinks */}
            </div>
          </div>

          {/* <!-- ////// Histry --> */}

          <div class="py-4">
            <div class="mining-area">
              <div class="col-xxl-12 col-md-12 pt-5">
                <div class=" text-white card ">
                  <div class="card-header d-flex flex-wrap justify-content-between">
                    <h4 class="title text-primary">My Investemt</h4>
                  </div>
                  <div class="card-body  text-white p-2">
                    <div class="text-white table-responsive ">
                      <table
                        style={{ background: "#1A233A !important" }}
                        class="text-white table table-responsive-md "
                      >
                        <thead>
                          <tr
                            style={{ color: "#bcd0f7 !important" }}
                            class=" mx-auto table-header"
                          >
                            <th>S.N.</th>
                            <th>Loan&nbsp;No.&nbsp;|&nbsp;Plan</th>
                            <th>Amount</th>
                            <th>Installment&nbsp;Amount</th>
                            <th>Installment</th>
                            <th>Next&nbsp;Installment</th>
                            <th>Paid</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loanHistory?.length !== 0 ? (
                            loanHistory?.map((data, index) => {
                              if (data) {
                                return (
                                  <MobileLoanSingleHistoryItem
                                    data={data}
                                    index={index}
                                    key={data?._id}
                                    paginateData={loanHistoryPaginateData}
                                  ></MobileLoanSingleHistoryItem>
                                );
                              }
                            })
                          ) : (
                            <tr>
                              <td
                                className="text-muted text-center"
                                colSpan="100%"
                              >
                                No records available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    {/* paginateLinks */}
                    <CustomPaginationMobile
                      data={loanHistoryPaginateData}
                      handlePage={handleloanHistoryPage}
                    ></CustomPaginationMobile>
                    {/* paginateLinks */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default MobileLoan;
