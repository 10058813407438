import React, { useEffect, useState } from "react";
import SingleItem from "./SingleItem";
import Skeleton from "react-loading-skeleton";
import "./MobileCryptoChain.css";
import { Link } from "react-router-dom";
const MobileCryptoChain = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://api.royeluniverse.com/api/user/trade/log/popular/market/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
      });
  }, []);

  const SymbolString = data.reduce(
    (acc, curr) => `${acc}${curr.symbol}${curr.currency},`,
    ""
  );
  const Symbol = SymbolString.substring(0, SymbolString?.length - 1);

  const [livePrice, setLivePrice] = useState([]);

  useEffect(() => {
    fetchData();
  }, [data]);

  const fetchData = async () => {
    if (Symbol?.length !== 0) {
      const data = await fetch(
        `https://marketdata.tradermade.com/api/v1/live?currency=${Symbol}&api_key=LuM6vmf8KmzvyYG_H1h-`
      );
      const apiResponse = await data.json();
      setLivePrice(apiResponse?.quotes);
      if (apiResponse?.quotes) {
        setLoading(false);
      }
    }
  };

  if (loading) {
    return (
      <>
        <div className="container-custom">
          <Skeleton height={15} count={10} />
        </div>
      </>
    );
  } else {
    return (
      <>
        <section className="popular-market-section">
          <div className="container">
            <div className="row popular-market-title">
              <div className="col-6">
                <p>Name</p>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-6 text-center">
                    <p>Last Price</p>
                  </div>
                  <div className="col-6 text-end">
                    <p>24h Chg%</p>
                  </div>
                </div>
              </div>
            </div>
            {data.length !== 0 ? (
                    data.map((data, index) => {
                      if (data) {
                        return (
                          <SingleItem
                            data={data}
                            index={index}
                            key={data._id}
                            livePrice={livePrice}
                          ></SingleItem>
                        );
                      }
                    })
                  ) : (
                    <>
                      <div className="col">
                        <div className="text-muted text-center">
                          Data not found
                        </div>
                      </div>
                    </>
                  )}
          
          </div>
        </section>

      </>
    );
  }
};

export default MobileCryptoChain;
