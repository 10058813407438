import React, { useEffect, useState } from 'react';
import Metals from './Metals';

const MetalsPage = () => {
    const [ Metalsdata, setMetalsData] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        fetch(`https://api.royeluniverse.com/api/user/trade/market/metals/view`, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(data => {
                setMetalsData(data.data);
                setLoading(false);
            });


    }, [])

    

        
    const  MetalsSymbolString = Metalsdata.reduce(
        (acc, curr) => `${acc}${curr.symbol},`,
        ""
      );
      const  MetalsSymbol = MetalsSymbolString.substring(0, MetalsSymbolString?.length - 1);
    
      const [ MetalslivePrice, setMetalsLivePrice] = useState([]);
    
      useEffect(() => {
        fetchData();
      }, [Metalsdata]);
    
      const fetchData = async () => {
        if (MetalsSymbol?.length !== 0) {
          const data = await fetch(
            `https://marketdata.tradermade.com/api/v1/live?currency=${MetalsSymbol}&api_key=SA2ZwXn7pBYC-odwXamW`
          );
          const apiResponse = await data.json();
          setMetalsLivePrice(apiResponse?.quotes);
        }
      };




      const [Energisesdata, setEnergisesData] = useState([]);
  
  
      useEffect(() => {
          fetch(`https://api.royeluniverse.com/api/user/trade/market/energises/view`, {
              method: 'GET',
          })
              .then(res => res.json())
              .then(data => {
                setEnergisesData(data.data);
              });
  
  
      }, [])
  
      
  
      
      const EnergisesSymbolString = Energisesdata.reduce(
          (acc, curr) => `${acc}${curr.symbol},`,
          ""
        );
        const EnergisesSymbol = EnergisesSymbolString.substring(0, EnergisesSymbolString?.length - 1);
      
        const [EnergiseslivePrice, setEnergisesLivePrice] = useState([]);
      
        useEffect(() => {
            EnergisesfetchData();
        }, [Energisesdata]);
      
        const EnergisesfetchData = async () => {
          if (EnergisesSymbol?.length !== 0) {
            const data = await fetch(
              `https://marketdata.tradermade.com/api/v1/live?currency=${EnergisesSymbol}&api_key=SA2ZwXn7pBYC-odwXamW`
            );
            const apiResponse = await data.json();
            setEnergisesLivePrice(apiResponse?.quotes);
          }
        };
      
      
    
    return (
        <>
            <Metals MetalsData={Metalsdata} MetalslivePrice={MetalslivePrice} Energisesdata={Energisesdata} EnergiseslivePrice={EnergiseslivePrice}></Metals>
        </>
    );


};

export default MetalsPage;