import React from "react";
import "./MobileDashboardCard.css";
import { Link } from "react-router-dom";
const MobileDashboardCard = () => {
  return (
    <>
      <section className="pt-3 pb-2 mobile-home-sft-area text-center">
        <div className="container">
          <div className="mobile-home-sft ">
            <div className="d-flex align-items-center justify-content-between">
              <Link to="/user/assets/deposit">
                <i
                  className="fa-solid fa-money-bill-1-wave"
                  style={{ color: "#536DFE" }}
                ></i>
                <i className=""></i>
                {/* <i className="fa-solid fa-sack-dollar " style={{color:"#536DFE",}}></i> */}
                <p>Deposit</p>
              </Link>
              <Link to="/user/bitcoin/mining">
                <i
                  className="fa-brands fa-bitcoin"
                  style={{ color: "orange" }}
                ></i>
                {/* <i style={{color:"orange"}} className="fas bg-white section_div_i  fa-industry"></i> */}
                {/* <i className="fa-solid fa-chart-line"></i> */}
                <p>B. Mining</p>
              </Link>
              <Link to="/user/gold/mining">
                <i
                  className="fa-solid fa-coins"
                  style={{ color: "green" }}
                ></i>
                {/* <i className="fa-solid fa-arrow-up-right-dots"></i> */}
                {/* <i style={{color:"green"}} className="fas bg-white section_div_i  fa-location-arrow"></i> */}
                <p>G. Mining</p>
              </Link>
              <Link to="/user/forex">
                <i
                  className="fa-solid fa-sack-dollar"
                  style={{ color: "red" }}
                ></i>
                {/* <i style={{color:"red"}}  className="fas bg-white section_div_i  fa-percent"></i> */}
                {/* <i className="fa-solid fa-f"></i> */}
                <p>Forex</p>
              </Link>
            </div>
          </div>
          <div className="mobile-home-sft ">
            <div className="d-flex align-items-center justify-content-between">
              <Link to="/user/stocks">
                <i
                  className="fa-brands fa-meta"
                  style={{ color: "blue" }}
                ></i>
                {/* <i className="fa-solid fa-chart-line" style={{color:"yello"}}></i> */}
                <p>Stocks</p>
              </Link>
              <Link to="/user/invest/plan">
                <i
                  className="fa-solid fa-circle-dollar-to-slot"
                  style={{ color: "#ff00f2" }}
                ></i>
                <p>Invest Plan</p>
              </Link>

              <Link to="#">
                <i
                  className="fa-solid fa-gifts"
                  style={{ color: "red" }}
                ></i>
                <p>Gift</p>
              </Link>
              <Link to="/user/apply/loan">
                <i
                  className="fa-solid fa-hand-holding-dollar"
                  style={{ color: "green" }}
                ></i>
                <p>Loan</p>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MobileDashboardCard;
