import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../Contexts/AuthContext/AuthProvider';
import { Circles } from 'react-loader-spinner';
const KycRoute = ({children}) => {

    const { authUser } = useContext(AuthContext);
    const location = useLocation();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch(`https://api.royeluniverse.com/api/admin/user/view/single/${authUser?._id}`, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(data => {
                setData(data.data);
                setLoading(false)
            });


    }, [authUser?._id]);
    if(loading){
        return (
            <>
            <div className="loader-section d-flex justify-content-center align-items-center desktop-version">
            <Circles
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
            </>
        );
    }else{
        if(data?.kv){
            return <Navigate to='/assets/withdraw' sate={{from:location}} replace ></Navigate>
            
        }else{
            return children;  
        }
    }
    
    
}

export default KycRoute;