import React from "react";
import "./MobileSideBarMenu.css";
import { Link } from "react-router-dom";
import user from "./../user.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
const MobileSideBarMenu = ({ handleShow, SignOut, authUser }) => {
  return (
    <>
      <div id="more_div" className="mobile-sidebar-menu-area">
        <div className="mobile-sidebar-menu-close-btn" onClick={handleShow}>
          <span id="more_off" className="more_div_div_span">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z"></path>
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </span>
        </div>
        <div className="more_div_div">
          {/* <!--========= Mobile view menu offcanvas =========--> */}
          <div className="mobile-sidebar-nav-area ">
            <div className="d-flex nav_div_un ">
              <div className="nav_div_un_div_un">
                <h4 style={{ color: "orange", fontSize: "14px" }}>
                  <b>
                    {authUser?.fname} {authUser?.lname}
                  </b>
                </h4>
                <h6 style={{ color: "gray", fontSize: "12px" }}>
                  UID: {authUser?.uid}
                </h6>
                <h6 style={{ color: "gray", fontSize: "12px" }}>
                  Credit Score: 10
                </h6>
                <h6 style={{ color: "gray", fontSize: "12px" }}>
                  Balance: {authUser?.balance}
                </h6>
              </div>
              <div className="nav_div_un_div_deux rounded-full user_peofile">
                {authUser ? (
                  <>
                    <LazyLoadImage
                      effect="blur"
                      src={user}
                      alt="user"
                      width="80%"
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <hr
              style={{
                width: "100%",
                backgroundColor: "#2b3139",
                marginBottom: "0px",
              }}
            />
            <div style={{ marginTop: "0px" }} className="mobile_canvas_menu">
              <ul className="side_menu">
                <li>
                  <Link
                    className="menu-item "
                    to="/user/dashboard"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "6px",
                        backgroundColor: "blue",
                        borderRadius: "25px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fa text-white fa-home"></i>
                    </span>
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item "
                    to="/user/markets"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#F44336",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas text-white  fa-chart-line"></i>
                    </span>
                    Market
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item active"
                    to="/user/trade"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#9C27B0",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas text-white  fa-exchange-alt"></i>
                    </span>
                    Trade
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item active"
                    to="/user/trade/history"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#9C27B0",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas text-white  fa-exchange-alt"></i>
                    </span>
                    Trade History
                  </Link>
                </li>

                <li>
                  <Link
                    className="menu-item "
                    to="/user/bitcoin/mining"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#FDD835",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="far text-white fa-credit-card"></i>
                    </span>
                    Bitcoin Mining
                  </Link>
                </li>

                <li>
                  <Link
                    className="menu-item "
                    to="/user/gold/mining"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#FDD835",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="far text-white fas fa-hammer"></i>
                    </span>
                    Gold Mining
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item "
                    to="/user/invest/plan"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#FDD835",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="far text-white fas fa-hammer"></i>
                    </span>
                    Invest Plan
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item "
                    to="/user/invest/history"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#FDD835",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="far text-white fas fa-hammer"></i>
                    </span>
                    Invest Plan History
                  </Link>
                </li>

                <hr
                  style={{
                    width: "100%",
                    backgroundColor: "#2b3139",
                    marginBottom: "0px",
                  }}
                />
                <li>
                  <Link
                    className="menu-item "
                    to="/user/assets/deposit"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#FF69B4",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="far text-white  fa-credit-card"></i>
                    </span>
                    Deposit
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item "
                    to="/user/deposit/history"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#FF69B4",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="far text-white  fa-credit-card"></i>
                    </span>
                    Deposit History
                  </Link>
                </li>

                <li>
                  <Link
                    className="menu-item "
                    to="/user/assets/withdraw"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#D2691E",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas  text-white  fa-wallet"></i>
                    </span>
                    Withdrawal
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item "
                    to="/user/withdraw/history"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#D2691E",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas  text-white  fa-wallet"></i>
                    </span>
                    Withdrawal History
                  </Link>
                </li>
                <hr
                  style={{
                    width: "100%",
                    backgroundColor: "#2b3139",
                    marginBottom: "0px",
                  }}
                />
                <li>
                  <Link
                    className="menu-item"
                    to="/user/apply/loan"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#000000",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas  text-white  fa-wallet"></i>
                    </span>
                    Apply for a loan
                  </Link>
                </li>

                <li>
                  <Link className="menu-item" to="#" onClick={handleShow}>
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#DAA520",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas text-white  fa-file-alt"></i>
                    </span>
                    New Coin
                  </Link>
                </li>

                <hr
                  style={{
                    width: "100%",
                    backgroundColor: "#2b3139",
                    marginBottom: "0px",
                  }}
                />
                <li>
                  <Link
                    className="menu-item "
                    to="/user/profile/update"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "tomato",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas text-white  fa-user"></i>
                    </span>
                    Profile change
                  </Link>
                </li>

                <li>
                  <Link
                    className="menu-item"
                    to="/user/password/update"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "orange",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas text-white  fa-flask"></i>
                    </span>
                    Change Password
                  </Link>
                </li>

                <li>
                  <Link
                    className="menu-item"
                    to="/user/id-auth/Kyc"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "orange",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas text-white  fa-flask"></i>
                    </span>
                    KYC
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item"
                    to="/user/contact"
                    onClick={handleShow}
                  >
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "#4CAF50",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas text-white  fa fa-headphones"></i>
                    </span>
                    Help Center
                  </Link>
                </li>
                <li>
                  <Link className="menu-item" to="#" onClick={handleShow}>
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "indigo",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas text-white  fa fa-download"></i>
                    </span>
                    App Download-Android
                  </Link>
                </li>

                <li>
                  <Link className="menu-item" to="#" onClick={handleShow}>
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "black",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas text-white  fa fa-download"></i>
                    </span>
                    App Download-iOS
                  </Link>
                </li>

                <li>
                  <Link className="menu-item text-danger" onClick={SignOut}>
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "red",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas text-white  fa-sign-out-alt"></i>
                    </span>
                    Log Out
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileSideBarMenu;
