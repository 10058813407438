import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import GridSingleItems from "./GridSingleItems";
import CustomPaginationMobile from "../../../CustomPagination/CustomPaginationMobile";
import Loader from "../../../Loader/Loader";
import "./News.css";
const News = () => {
  const [loading, setLoading] = useState(true);
  const [paginateData, setPaginateData] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`https://api.royeluniverse.com/api/frontend/home/news/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setPaginateData(data);
        setLoading(false);
      });
  }, []);

  const handlePage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `https://api.royeluniverse.com/api/frontend/home/news/view?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setData(response?.data?.data);
          setPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return (
      <>
        <Loader></Loader>
      </>
    );
  } else {
    return (
      <>
        <main className="mobile-news-section news-section">
          <div className="container">
            <div className="news-breadcrumb">
              <h5>News</h5>
            </div>
            <div className="news-dis-area">
              <p>
                Prospect Capital Corporation (NASDAQ: PSEC) is a leading
                provider of flexible private debt and equity capital to
                sponsor-owned and non-sponsor-owned middle-market companies in
                the United States and Canada. PSEC is a publicly-traded
                closed-end investment company (NASDAQ: PSEC) that has elected to
                be regulated as a business development company under the
                Investment Company Act of 1940, as amended. PSEC is managed by
                Prospect Capital Management L.P.
              </p>
            </div>
            <div className="news-menu-area">
              <div className="irwTabs col-xs-12">
                <ul className="irwCommonTabs">
                  <li className="active">
                    <a className="news_tag" rel="all" href="#all">
                      All Releases
                    </a>
                  </li>
                  <li>
                    <a className="news_tag" rel="earnings" href="#earnings">
                      Earnings Releases
                    </a>
                  </li>
                  <li>
                    <a className="news_tag" rel="portfolio" href="#portfolio">
                      Portfolio Releases
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="main-newes-area">
              <div className="news-grid-area">
                <div className="mobile-news-grid">
                  {data.length !== 0 ? (
                    data.map((data, index) => {
                      if (data) {
                        return (
                          <GridSingleItems
                            data={data}
                            index={index}
                            key={data._id}
                            paginateData={paginateData}
                          ></GridSingleItems>
                        );
                      }
                    })
                  ) : (
                    <>
                      {/* <div className="col">
                        <div className="card">
                          <div className="card-body">
                            <h6 className="">Earnings Release</h6>
                            <h5>
                              <Link
                                to="/blog/details/8578765785"
                                className="irwGaLabel irwEventTracking irwGaHasFile"
                              >
                                Prospect Capital Announces September 2023
                                Financial Results and Declares Steady Monthly
                                Dividends through January 2024 of $0.06 Per
                                Common Share
                              </Link>
                            </h5>
                            <p>
                              <Link
                                to="/blog/details/8578765785"
                                className="irwGaLabel irwEventTracking irwGaHasFile"
                              >
                                Prospect Lorem ipsum dolor, sit amet consectetur
                                adipisicing elit. Adipisci quod voluptas unde
                                rem hic saepe repellat deserunt quos maxime,
                                rerum repellendus! Consequatur eaque, distinctio
                                voluptates dolor aut fugit dignissimos maiores?
                              </Link>
                              <a href="" className="coninue-btn">
                                <i className="fa-solid fa-chevron-right"></i>{" "}
                                Continue Reading
                              </a>
                            </p>

                            <span>11/8/2023</span>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="card">
                          <div className="card-body">
                            <h6 className="">Earnings Release</h6>
                            <h5>
                              <a
                                target="_self"
                                href="/News/news-details/2023/Prospect-Capital-Announces-September-2023-Financial-Results-and-Declares-Steady-Monthly-Dividends-through-January-2024-of-0.06-Per-Common-Share/default.aspx"
                                className="irwGaLabel irwEventTracking irwGaHasFile"
                              >
                                Prospect Capital Announces September 2023
                                Financial Results and Declares Steady Monthly
                                Dividends through January 2024 of $0.06 Per
                                Common Share
                              </a>
                            </h5>
                            <p>
                              <a
                                target="_self"
                                href="/News/news-details/2023/Prospect-Capital-Announces-September-2023-Financial-Results-and-Declares-Steady-Monthly-Dividends-through-January-2024-of-0.06-Per-Common-Share/default.aspx"
                                className="irwGaLabel irwEventTracking irwGaHasFile"
                              >
                                Prospect Lorem ipsum dolor, sit amet consectetur
                                adipisicing elit. Adipisci quod voluptas unde
                                rem hic saepe repellat deserunt quos maxime,
                                rerum repellendus! Consequatur eaque, distinctio
                                voluptates dolor aut fugit dignissimos maiores?
                              </a>
                              <a href="" className="coninue-btn">
                                <i className="fa-solid fa-chevron-right"></i>{" "}
                                Continue Reading
                              </a>
                            </p>

                            <span>11/8/2023</span>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="card">
                          <div className="card-body">
                            <h6 className="">Earnings Release</h6>
                            <h5>
                              <a
                                target="_self"
                                href="/News/news-details/2023/Prospect-Capital-Announces-September-2023-Financial-Results-and-Declares-Steady-Monthly-Dividends-through-January-2024-of-0.06-Per-Common-Share/default.aspx"
                                className="irwGaLabel irwEventTracking irwGaHasFile"
                              >
                                Prospect Capital Announces September 2023
                                Financial Results and Declares Steady Monthly
                                Dividends through January 2024 of $0.06 Per
                                Common Share
                              </a>
                            </h5>
                            <p>
                              <a
                                target="_self"
                                href="/News/news-details/2023/Prospect-Capital-Announces-September-2023-Financial-Results-and-Declares-Steady-Monthly-Dividends-through-January-2024-of-0.06-Per-Common-Share/default.aspx"
                                className="irwGaLabel irwEventTracking irwGaHasFile"
                              >
                                Prospect Lorem ipsum dolor, sit amet consectetur
                                adipisicing elit. Adipisci quod voluptas unde
                                rem hic saepe repellat deserunt quos maxime,
                                rerum repellendus! Consequatur eaque, distinctio
                                voluptates dolor aut fugit dignissimos maiores?
                              </a>
                              <a href="" className="coninue-btn">
                                <i className="fa-solid fa-chevron-right"></i>{" "}
                                Continue Reading
                              </a>
                            </p>

                            <span>11/8/2023</span>
                          </div>
                        </div>
                      </div> */}
                      <div className="col">
                        <div className="text-muted text-center">
                          Data not found
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/* paginateLinks */}
              <CustomPaginationMobile
                data={paginateData}
                handlePage={handlePage}
              ></CustomPaginationMobile>
              {/* paginateLinks */}
            </div>
          </div>
        </main>
      </>
    );
  }
};

export default News;
