import React, { useEffect, useRef, useState } from "react";
import "./MobileSilder.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Autoplay } from "swiper/modules";
import axios from "axios";
import Loader from "../../../Loader/Loader";
const MobileSilder = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`https://api.royeluniverse.com/api/user/slider/view`)
      .then((res) => {
        setData(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("API call error:", err?.message);
      });
  }, []);

  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };

  if (loading) {
    return (
      <>
        <Loader></Loader>
      </>
    );
  }

  return (
    <>
      <section className="mobile-version">
        <div className="Mobile-version-slider">
          <div className="container">
            {data?.length !== 0 ? (
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                loop={true}
                navigation={true}
                modules={[Autoplay]}
                onAutoplayTimeLeft={onAutoplayTimeLeft}
                className="user-home-slider"
              >
                {data.map((data, index) => {
                  if (data) {
                    return (
                      <SwiperSlide key={data?._id}>
                        <LazyLoadImage
                          alt="img"
                          effect="blur"
                          src={`https://api.royeluniverse.com/${data?.image_url}`}
                        />
                      </SwiperSlide>
                    );
                  }
                })}
                <div className="autoplay-progress" slot="container-end">
                  <svg viewBox="0 0 48 48" ref={progressCircle}>
                    <circle cx="24" cy="24" r="20"></circle>
                  </svg>
                  <span ref={progressContent}></span>
                </div>
              </Swiper>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default MobileSilder;
