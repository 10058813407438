import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../../Contexts/AuthContext/AuthProvider';
import axios from 'axios';
import CustomPaginationMobile from '../../../../CustomPagination/CustomPaginationMobile';
import SingleItem from './SingleItem';
import Loader from '../../../../Loader/Loader';

const MobileWithdrawHistory = () => {

  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [paginateData, setPaginateData] = useState([]);

  useEffect(() => {
    if (authUser?._id) {
      fetch(`https://api.royeluniverse.com/api/user/withdrawal/view/${authUser?._id}`, {
        method: 'GET',
      })
        .then(res => res.json())
        .then(data => {
          setData(data.data);
          setPaginateData(data);
          setLoading(false);

        });


    }

  }, [authUser?._id])



  const handlePage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `https://api.royeluniverse.com/api/user/withdrawal/view/${authUser?._id}?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setData(response?.data?.data);
          setPaginateData(response?.data);
        })
        .catch((error) => { });
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return (
        <>
            <Loader></Loader>
        </>
    );

} else {
  return (
    <>
      <section className="container">
        <div className="mobile-history-table  mb-5 mt-2">
          <div className="user-deposit-history px-2">
            <div className="user-referral-history-titile">
              <h3> withdraw History</h3>
            </div>
            <div className="user-referral-history-area  ">
              <div className="user-referral-history-items trade-history-table">
                <table className="user-referral-history-table ">
                  <thead>
                    <tr className="table-headers">
                      <th>Network&nbsp;Type</th>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Wallet&nbsp;Address</th>
                      <th>Transaction</th>
                      <th>Progress</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data?.length !== 0 ? (
                        data.map((data, index) => {
                          if (data) {
                            return (
                              <SingleItem
                                data={data} key={data?._id} paginateData={paginateData}
                              ></SingleItem>
                            );
                          }
                        })
                      ) : (
                        <tr>
                          <td className="text-muted text-center" colSpan="100%">
                            No records available
                          </td>
                        </tr>
                      )
                    }

                  </tbody>
                </table>
              </div>
              {/* paginateLinks */}
              <CustomPaginationMobile
                data={paginateData} handlePage={handlePage}
              ></CustomPaginationMobile>
              {/* paginateLinks */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
 
};

export default MobileWithdrawHistory;