import React from "react";

const SingleItem = ({
  data,
  handleSubmitData,
  setFindLoanId,
  refSubmitDis,
  errorMessage,
}) => {
  // {data?.}
  if (data?.template === 1) {
    return (
      <>
        <div className="col-sm-6 col-md-6 loan-card">
          <div className="card text-center">
            <div className="title">
              <div className="loan-plane-icon">
              <i className="fa-solid fa-landmark"></i>
              </div>
              <h2>{data?.name}</h2>
            </div>
            <div className="price">
              <h4>
                {data?.percentage}%
                <span className="text-small">
                  &nbsp;/ {data?.period === 1 ? "Day" : "Days"}{" "}
                </span>
              </h4>
            </div>
            <div className="option">
              <ul>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Take Minimum: $
                  {data?.minimum_amount}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Take Maximum: $
                  {data?.maximum_amount}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Per Installment:{" "}
                  {data?.percentage}%
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>
                  Installment Interval: {data?.period === 1 ? "Day" : "Days"}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Total
                  Installment: 1
                </li>
              </ul>
            </div>
            <form onSubmit={handleSubmitData}>
            <input type="hidden" name="id" value={data?._id} />
              <div className="modal-body">
                <div className="form-group">
                  <label for="amount" className="required">
                    Amount
                  </label>
                  <div className="input-group  custom-input-group">
                    <input
                      type="number"
                      step="any"
                      name="amount"
                      className="form-control form--control"
                      placeholder="Enter An Amount"
                      id="amount"
                      required
                    />
                    <span className="input-group-text "> USD </span>
                  </div>
                  {errorMessage?.message && (
                    <span className="text-dark py-2">
                      {errorMessage?.message}
                    </span>
                  )}
                </div>
                <button
                  type="submit"
                  className=" text-white mt-2 submit-btn  w-100"
                  onClick={() => setFindLoanId(data?._id)}
                  ref={refSubmitDis}
                >
                  Apply Loan
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
  if (data?.template === 2) {
    return (
      <>
        <div className="col-sm-6 col-md-6 loan-card">
          <div className="card text-center">
            <div className="title">
              <div className="loan-plane-icon">
              <i className="fa-solid fa-landmark"></i>
              </div>
              <h2>{data?.name}</h2>
            </div>
            <div className="price">
              <h4>
                {data?.percentage}%
                <span className="text-small">
                  &nbsp;/ {data?.period === 1 ? "Day" : "Days"}{" "}
                </span>
              </h4>
            </div>
            <div className="option">
              <ul>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Take Minimum: $
                  {data?.minimum_amount}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Take Maximum: $
                  {data?.maximum_amount}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Per Installment:{" "}
                  {data?.percentage}%
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>
                  Installment Interval: {data?.period === 1 ? "Day" : "Days"}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Total
                  Installment: 1
                </li>
              </ul>
            </div>
            <form onSubmit={handleSubmitData}>
            <input type="hidden" name="id" value={data?._id} />
              <div className="modal-body">
                <div className="form-group">
                  <label for="amount" className="required">
                    Amount
                  </label>
                  <div className="input-group  custom-input-group">
                    <input
                      type="number"
                      step="any"
                      name="amount"
                      className="form-control form--control"
                      placeholder="Enter An Amount"
                      id="amount"
                      required
                    />
                    <span className="input-group-text "> USD </span>
                  </div>
                </div>
                <button
                  type="submit"
                  className=" text-white mt-2 submit-btn  w-100"
                  onClick={() => setFindLoanId(data?._id)}
                  ref={refSubmitDis}
                >
                  Apply Loan
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
  if (data?.template === 3) {
    return (
      <>
        <div className="col-sm-12 col-md-6 loan-card">
          <div className="card text-center">
            <div className="title">
              <div className="loan-plane-icon">
              <i className="fa-solid fa-landmark"></i>
              </div>
              <h2>{data?.name}</h2>
            </div>
            <div className="price">
              <h4>
                {data?.percentage}%
                <span className="text-small">
                  &nbsp;/ {data?.period === 1 ? "Day" : "Days"}{" "}
                </span>
              </h4>
            </div>
            <div className="option">
              <ul>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Take Minimum: $
                  {data?.minimum_amount}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Take Maximum: $
                  {data?.maximum_amount}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Per Installment:{" "}
                  {data?.percentage}%
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>
                  Installment Interval: {data?.period === 1 ? "Day" : "Days"}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Total
                  Installment: 1
                </li>
              </ul>
            </div>
            <form onSubmit={handleSubmitData}>
            <input type="hidden" name="id" value={data?._id} />
              <div className="modal-body">
                <div className="form-group">
                  <label for="amount" className="required">
                    Amount
                  </label>
                  <div className="input-group  custom-input-group">
                    <input
                      type="number"
                      step="any"
                      name="amount"
                      className="form-control form--control"
                      placeholder="Enter An Amount"
                      id="amount"
                      required
                    />
                    <span className="input-group-text "> USD </span>
                  </div>
                </div>
                <button
                  type="submit"
                  className=" text-white mt-2 submit-btn  w-100"
                  onClick={() => setFindLoanId(data?._id)}
                  ref={refSubmitDis}
                >
                  Apply Loan
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
};

export default SingleItem;
