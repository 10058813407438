import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import { Circles } from "react-loader-spinner";
import UserImageUpdate from "../../../UserImageUpdate/UserImageUpdate";
import { toast } from "react-toastify";
import axios from "axios";
import user from "./user.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Profile = () => {
  const { authUser } = useContext(AuthContext);

  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `https://api.royeluniverse.com/api/admin/user/view/single/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setUserData(data.data);
          setLoading(false);
        });
    }
  }, [authUser]);

  const [KYCData, setKYCData] = useState({});

  useEffect(() => {
    if (authUser?._id) {
      fetch(`https://api.royeluniverse.com/api/kyc/verify/view/${authUser?._id}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setKYCData(data.data);
        });
    }
  }, [authUser?._id]);

  const UpdateProfilePhoto = async (image) => {
    const storeData = { picture: image };

    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    if (userData?._id) {
      axios
        .put(
          `https://api.royeluniverse.com/api/user/auth/profile/update/${userData?._id}`,
          storeData,
          config
        )
        .then((data) => {
          toast.success(`${data.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((error) => {
          if (error?.response?.data?.success === false) {
            toast.error(`${error?.response?.data?.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        });
    } else {
      toast.error(`something is worng`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  if (loading) {
    return (
      <>
        <div className="loader-section d-flex justify-content-center align-items-center">
          <Circles
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <section className="user-profile-name-area">
          <div className="container">
            <div className="pt-4">
              <div className="d-flex">
                <div className="user-image me-2">
                  <LazyLoadImage alt="img" effect="blur" src={user} />
                </div>
                <div className="user-details w-100">
                  <div className="user-name-area">
                    <div className="d-flex align-items-center">
                      <h4>
                        {authUser?.fname} {authUser?.lname}
                      </h4>
                    </div>
                  </div>
                  <div className=" row justify-content-between">
                    <div className="col-6">
                      <div className="user-account-details">
                        <p>Account</p>
                        <span>{userData?.email}</span>
                      </div>
                      <div className="user-account-details">
                        <p>UID</p>
                        <span>{userData?.uid}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container">
          <div className="pt-5">
            <div className="row">
              <div className="col-7">
                <div className="user-profile-identification ">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="user-total-value">
                      <h3>Total Balance</h3>
                      <h4 className="user-usdt-value">
                        {userData?.balance} USD
                      </h4>
                      <Link
                        to="/assets/deposit"
                        className="btn btn-primary px-5 fw-normal d-block mt-3"
                      >
                        Deposit
                      </Link>
                    </div>
                    <div className="user-assets">
                      <Link className="user-institutional-verification-btn fw-bolder">
                        Asset Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default Profile;
