import React, { useEffect, useState } from "react";
import SingleItem from "./SingleItem";

const Forex = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(`https://api.royeluniverse.com/api/user/trade/market/forex/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
      });
  }, []);

  const SymbolString = data.reduce(
    (acc, curr) => `${acc}${curr.base_currency}${curr.quote_currency},`,
    ""
  );
  const Symbol = SymbolString.substring(0, SymbolString?.length - 1);
 
  const [livePrice, setLivePrice] = useState([]);

  useEffect(() => {
    fetchData();
  }, [data]);

  const fetchData = async () => {
    if (Symbol?.length !== 0) {
      const data = await fetch(
        `https://marketdata.tradermade.com/api/v1/live?currency=${Symbol}&api_key=E973yln-XwbcM9nvff0R`
      );
      const apiResponse = await data.json();
      setLivePrice(apiResponse?.quotes);
    }
  };

  return (
    <>
     <div id="a1" className="containerTab">
        <div
          className="d-flex pb-2 coin_style_div gap-2 flex-column mb-3 mt-1"
          id="coinContainer"
        >
          <h6
            style={{ backgroundColor: "orangered" }}
            className="text-center coin_style_h6 py-2"
          >
            Forex
          </h6>
        </div>
      </div>

      <div className="tab-content" id="pills-tabContent">
        <div className="tab-pane fade show active">
          <ul className="coin_menu mt-2">
            {data.map((data, index) => (
              <SingleItem
                data={data}
                key={data?._id}
                index={index}
                livePrice={livePrice}
              ></SingleItem>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Forex;
